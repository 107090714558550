import React from 'react'
import Layout from '../Components/Layout'
import { Box, Typography } from '@mui/material';
import aboutus_banner from "../Assets/aboutus_banner.png";

const AboutUs = () => {
  return (
    <Layout>
      <Box sx={{width:'100%',minHeight:'40vh',background:`url(${aboutus_banner}) no-repeat`,backgroundPosition:'center',backgroundSize:'100% 100%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>

      <Typography variant='h3' sx={{fontWeight:'700',fontSize:{lg:'60px',md:'50px',sm:'40px',xs:'20px'},lineHeight:{lg:'90px',md:'70px',sm:'50px',xs:'30px'},color:'#43C69B'}}>About Us</Typography>

      <Typography sx={{fontWeight:'400',textAlign:"center",fontSize:{lg:'22px',md:'15px',sm:'10px',xs:'10px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'10px'},color:'#fff'}}>At RAPIFY SMS SOLUTIONS, we are dedicated to revolutionizing communication for businesses and organizations through our innovative Bulk SMS services. With a commitment to excellence and a passion for delivering results, we strive to empower our clients with the tools they need to reach their audience effectively and efficiently.</Typography>




      </Box>

      <Box  sx={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
      <Box sx={{'& > .sms_para':{margin:{lg:'16px 0px',md:'14px 0px',sm:'12px 0px',xs:'9px 0px'}},'& > .sms_para > p':{fontSize:{xl:'25px',lg:'22px',md:'16px',sm:'12px',xs:'9px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'15px'},fontWeight:'600'},width:'90%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'
    ,'& > .sms_para > p > span':{fontSize:{xl:'25px',lg:'22px',md:'16px',sm:'12px',xs:'9px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'15px'},fontWeight:'600',color:'#43C69B'}}}>

        <Box className='sms_para'>
          <Box>
          <Typography sx={{fontWeight:'700',margin:'10px 0px',color:'#38B6FF',fontSize:{lg:'30px',md:'25px',sm:'18px',xs:'14px'}}}>Our Mission</Typography>
          </Box>

          <Typography>
          Our mission at RAPIFY SMS SOLUTIONS is simple: to provide unparalleled Bulk SMS solutions that drive engagement, enhance communication, and maximize impact for our clients. We believe that every message matters, and we are committed to delivering a service that exceeds expectations, every time.
          </Typography>
         
        </Box>

        <Box className='sms_para'>
          <Box>
          <Typography sx={{fontWeight:'700',margin:'10px 0px',color:'#38B6FF',fontSize:{lg:'30px',md:'25px',sm:'18px',xs:'14px'},fontFamily:'poppins'}}>Why Choose RAPIFY SMS?</Typography>
          </Box>

          <Typography sx={{margin:'30px 0px'}}>
          <Typography component={'span'}>Reliability:</Typography>
          With years of experience in the industry, we have built a reputation for reliability and dependability. Our robust infrastructure ensures that your messages are delivered promptly and securely, without fail.
          </Typography>

          <Typography sx={{margin:'30px 0px'}}>
          <Typography component={'span'}>Scalability:</Typography>
          Whether you're a small start-up or a large enterprise, our flexible solutions can scale to meet your needs. From sending a few hundred messages to millions, we have you covered.
          </Typography>

          <Typography sx={{margin:'30px 0px'}}>
          <Typography component={'span'}>Personalization:</Typography>
          We understand that every business is unique, which is why we offer personalized solutions tailored to your specific requirements. From custom sender IDs to targeted messaging, we help you connect with your audience on a personal level.
          </Typography>

          <Typography sx={{margin:'30px 0px'}}>
          <Typography component={'span'}>Analytics:</Typography>
          Gain valuable insights into the performance of your campaigns with our advanced analytics tools. Track delivery rates, open rates, click-through rates, and more to optimize your messaging strategy and achieve better results.
          </Typography>

          <Typography sx={{margin:'30px 0px'}}>
          <Typography component={'span'}>Customer Support:</Typography>
          Our dedicated team of experts is here to support you every step of the way. Whether you have questions, need assistance, or require technical support, we are just a phone call or email away.
          </Typography>
        </Box>
       
        <Box className='sms_para'>
          <Box>
          <Typography sx={{fontWeight:'700',margin:'10px 0px',color:'#38B6FF',fontSize:{lg:'30px',md:'25px',sm:'18px',xs:'14px'}}}>Our Commitment</Typography>
          </Box>

          <Typography>
          At RAPIFY SMS, we are committed to driving success for our clients. We believe in fostering long-term partnerships built on trust, transparency, and mutual success. When you choose RAPIFY SMS as your Bulk SMS provider, you can trust that you're in good hands.
          </Typography>
         
        </Box>
      </Box>
      </Box>

    </Layout>
  )
}

export default AboutUs
