import React from 'react';
import Layout from '../Components/Layout';
import { Box, Grid, Typography, IconButton } from '@mui/material';
import home_back_banner from "../Assets/home_back_banner.png";
import what_is_bulk_service_img from "../Assets/what_is_bulk_sms_service_img.png";
import {ramptechnoFeaturesData} from "../Data/RamptecnhoFeaturesData";
import {bulkSms} from "../Data/Type_of_sms_Data";
import {bulkWhatsapp} from "../Data/Type_of_sms_Data";
import StartYourJourney from '../Components/StartYourJourney';
import {percentageData} from "../Data/PercentageData";
import round_eclipse from "../Assets/round_eclipse.png";
import intractive_conversations from "../Assets/intractive_conversations.png";
import whatsapp_icon from "../Assets/whatsapp_image.png";


const Home = () => {

  const handleWhatsAppClick = () => {
    const phoneNumber = '918249646500'; 
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  };
  return (
    <Layout>
      <Box sx={{background:`url(${home_back_banner}) no-repeat`,backgroundSize:'cover',backgroundPosition:'center',width:'100%',height:{sm:'90vh',xs:"50vh"},display:'flex',justifyContent:'center',alignItems:'center'}}>
          <Box sx={{width:'90%'}}>
            <Typography sx={{fontSize:{xl:'53px',lg:'50px',md:'45px',sm:'30px',xs:'20px'},lineHeight:{xl:'75px',lg:'72px',md:'60px',sm:'40px',xs:'30px'},fontWeight:'600',fontFamily:'poppins',color:'#fff'}}>Bulk SMS Service Provider</Typography>
            <Typography sx={{fontSize:{xl:'53px',lg:'50px',md:'45px',sm:'30px',xs:'20px'},lineHeight:{xl:'75px',lg:'72px',md:'60px',sm:'40px',xs:'30px'},fontWeight:'600',fontFamily:'poppins',color:'#43C69B'}}>for Businesses</Typography>
            <Box sx={{width:'60%'}}>
            <Typography sx={{fontSize:{xl:'25px',lg:'25px',md:'20px',sm:'18px',xs:'12px'},lineHeight:{xl:'40px',lg:'40px',md:'30px',sm:'20px',xs:'16px'},fontWeight:'400',fontFamily:'poppins',color:'#fff'}}>Distribute bulk SMS updates to thousands of clients instantly. Rely on Rapify's SMS gateway for dependable delivery of promotional, transactional, and OTP messages at competitive rates in India.</Typography>
            <Grid  container
      justifyContent="flex-end" 
      alignItems="flex-end" 
      style={{
        position: 'fixed',
        bottom: '20px', 
        right: '-50px',
        zIndex: 1000,}}
>
      {/* <IconButton  onClick={handleWhatsAppClick}>
        <img src={whatsapp_icon} alt="whatsapp_icon" style={{ maxWidth: '20%', height: 'auto' }} />
      </IconButton> */}
    </Grid>
            </Box>
          </Box>
      </Box>

      {/* --------Bulk sms service---------- */}

      <Box sx={{width:'100%',display:'flex',justifyContent:'center',alignItems:'flex-start'}}>
        <Box sx={{width:'90%'}}>
          <Grid container sx={{display:'flex',justifyContent:'center',alignItems:'center',margin:{lg:'30px 0px',xs:'20px 0px'}}}>
            <Grid item sm={7}>
              <Typography sx={{fontSize:{xl:'36px',lg:'35px',md:'29px',sm:'20px',xs:'15px'},lineHeight:{xl:'43.88px',lg:'43.88px',md:'35px',sm:'30px',xs:'20px'},fontWeight:'700',color:'#000000'}}>What is Bulk SMS Service?</Typography>

             <Box sx={{'& > p':{fontSize:{xl:'28px',lg:'28px',md:'18px',sm:'13px',xs:'10px'},lineHeight:{xl:'40px',lg:'40px',md:'30px',sm:'20px',xs:'18px'},fontWeight:'400',fontFamily:'poppins',color:'#000000',margin:'30px 0px'}}}>
              <Typography>A bulk SMS service empowers businesses to swiftly communicate with  thousands of customers. Simply craft your message, upload your recipient  list, and hit “Send”.</Typography>

              <Typography>While akin to traditional message broadcasting, our bulk SMS service  offers enhanced functionality. Leveraging a robust SMS gateway like  RAPIFY SMS, customize sender IDs for brand recognition, schedule campaigns,  and automate messages via our SMS API</Typography>

              <Typography>Enjoy consistent, prompt message delivery with real-time analytics  providing valuable insights. Monitor delivery rates, failures, and  reasons, empowering businesses to make informed decisions promptly.</Typography>
             </Box>

            </Grid>
            <Grid item sm={5} sx={{display:'flex',justifyContent:'center',alignItems:'center','& > img':{
              // width:'100%',
              width:{lg:'505px',md:'400px',sm:'270px',xs:'200px'},
              height:{lg:'505px',md:'400px',sm:'270px',xs:'200px'}}}}>
              <img src={what_is_bulk_service_img} alt="what_is_bulk_service_img" />
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* ------RAPIFY SMS features---- */}

      <Box sx={{width:'100%',display:'flex',justifyContent:{sm:'space-between',xs:'center'},alignItems:'center',flexDirection:'column',margin:'50px 0px'}}>
        <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
          <Typography sx={{fontWeight:'700',color:'#000000',fontSize:{lg:'50px',md:'40px',sm:'30px',xs:'20px'},lineHeight:{lg:'60px',md:'50px',sm:'40px',xs:'25px'}}}>RAPIFY SMS Features</Typography>
          <Typography sx={{fontWeight:'400',color:'#000000',fontSize:{lg:'28px',md:'20px',sm:'16px',xs:'10px'},lineHeight:{lg:'40px',md:'30px',sm:'20px',xs:'20px'}}}>The right partner for your marketing efforts</Typography>
        </Box>

        <Box sx={{width:"90%"}}>
            <Grid container sx={{display:'flex',justifyContent:'center',alignItems:'center',margin:'50px 0px'}}>
              {
                ramptechnoFeaturesData?.map((item,i)=>(
                  <Grid md={3.5} sm={6} xs={12} key={i} sx={{display:'flex',justifyContent:'flex-start',alignItems:'center',flexDirection:'column',margin:{md:'40px 15px',sm:'5px 0px'},padding:'15px',height:{lg:'310px',md:'300px',sm:'200px',xs:'145px'},
                  '& > img':{margin:'20px 0px',width:{lg:'70px',md:'50px',sm:'35px',xs:'25px'},height:{lg:'70px',md:'50px',sm:'35px',xs:'25px'}}}}>
                    <img src={item.Img} alt="item.img" />
                    <Typography sx={{fontWeight:'600',color:'#38B6FF',fontSize:{lg:'28px',md:'20px',sm:'16px',xs:"13px"},lineHeight:{lg:'40px',md:'35px',sm:'20px',xs:'18px'}}}>{item.header}</Typography>
                    <Typography sx={{textAlign:"center",fontWeight:'300',color:'#000000',fontSize:{lg:'20px',md:'15px',sm:'12px',xs:"9px"},lineHeight:{lg:'40px',md:'35px',sm:'20px',xs:'18px'}}}>{item.about}</Typography>
                  </Grid>
                ))
              }
            </Grid>
        </Box>
      </Box>

      {/* ----------Type of sms section--------- */}

<Box sx={{width:'100%',minHeight:'100vh',borderRadius:'70px',backgroundColor:'#43C69B4D',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',margin:{sm:'50px 0px',xs:'10px 0px'},padding:{sm:'0px',xs:'0px 0px 47px 0px'}}}>

<Box sx={{width:'90%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
      <Typography sx={{fontWeight:'700',color:'#000000',fontSize:{lg:'36px',md:'30px',sm:'20px',xs:'18px'},lineHeight:{lg:'43px',md:'40px',sm:'30px',xs:'20px'},marginTop:'60px'}}>Types of Bulk SMS</Typography>

      <Grid container  sx={{display:'flex',justifyContent:'center',alignItems:'center',margin:{md:'50px 0px'}}}>
        {
          bulkSms?.map((item,i)=>(
            <Grid item key={i} md={3.5}  sm={3} xs={12} sx={{display:'flex',justifyContent:'flex-start',alignItems:'center',flexDirection:'column',margin:{sm:'40px 15px'},height:{lg:'715px',md:'600px',sm:'500px',xs:'270px'},padding:'15x',
            '& >div> div > img':{margin:'15px 0px',width:{lg:'70px',md:'50px',sm:'35px',xs:'25px'},height:{lg:'70px',md:'50px',sm:'35px',xs:'25px'}}}}>
              <Box component={'div'}>
              <Box component={'div'} sx={{width:{lg:'200px',md:'140px',sm:'100px',xs:'80px'},height:{lg:'200px',md:'140px',sm:'100px',xs:'80px'},background:'linear-gradient(180deg, #43C69B 0%, #010101 100%)',borderRadius:'50%',display:'flex',justifyContent:'center',alignItems:'center',margin:'50px 0px'}}
              >
                <img src={item.Img} alt="" />
              </Box>
              </Box>
              <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
              <Typography sx={{fontWeight:'600',color:'#38B6FF',fontSize:{lg:'28px',md:'20px',sm:'16px',xs:"13px"},lineHeight:{lg:'40px',md:'35px',sm:'20px',xs:'18px'},margin:'10px 0px'}}>{item.header}</Typography>
              <Typography sx={{fontWeight:'300',color:'#000000',fontSize:{lg:'20px',md:'15px',sm:'12px',xs:"9px"},lineHeight:{lg:'40px',md:'35px',sm:'20px',xs:'18px'}}}>{item.about}</Typography>
              </Box>
            </Grid>
          ))
        }
      </Grid>
</Box>

<Box sx={{width:'90%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',margin:{xs:'20px 0px'}}}>
      <Typography sx={{fontWeight:'700',color:'#000000',fontSize:{lg:'36px',md:'30px',sm:'20px',xs:'18px'},lineHeight:{lg:'43px',md:'40px',sm:'30px',xs:'20px'}}}>Types of Bulk WhatsApp</Typography>

      <Grid container  sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
        {
          bulkWhatsapp?.map((item,i)=>(
            <Grid item key={i} md={3.5}  sm={3} xs={12} sx={{display:'flex',justifyContent:'flex-start',alignItems:'center',flexDirection:'column',margin:{sm:'40px 15px'},height:{lg:'715px',md:'600px',sm:'500px',xs:'270px'},padding:'15x',
            '& >div> div > img':{margin:'15px 0px',width:{lg:'70px',md:'50px',sm:'35px',xs:'25px'},height:{lg:'70px',md:'50px',sm:'35px',xs:'25px'}}}}>
              <Box component={'div'}>
              <Box component={'div'} sx={{width:{lg:'200px',md:'140px',sm:'100px',xs:'80px'},height:{lg:'200px',md:'140px',sm:'100px',xs:'80px'},background:'linear-gradient(180deg, #43C69B 0%, #010101 100%)',borderRadius:'50%',display:'flex',justifyContent:'center',alignItems:'center',margin:'50px 0px'}}
              >
                <img src={item.Img} alt="" />
              </Box>
              </Box>
              <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
              <Typography sx={{fontWeight:'600',color:'#38B6FF',fontSize:{lg:'28px',md:'20px',sm:'16px',xs:"13px"},lineHeight:{lg:'40px',md:'35px',sm:'20px',xs:'18px'},margin:'10px 0px'}}>{item.header}</Typography>
              <Typography sx={{fontWeight:'300',color:'#000000',fontSize:{lg:'20px',md:'15px',sm:'12px',xs:"9px"},lineHeight:{lg:'40px',md:'35px',sm:'20px',xs:'18px'}}}>{item.about}</Typography>
              </Box>
            </Grid>
          ))
        }
      </Grid>
</Box>

</Box>

      {/* -------Attention Box----- */}

      <Box sx={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',margin:{sm:'40px 0px',xs:'20px 0px'}}}>
        <Box sx={{width:'85%'}}>
            <Grid container sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
              <Grid item sm={6} xs={12} sx={{padding:{md:'30px',sm:'20px'},margin:{sm:'0px 0px',xs:'10px 0px'}}}>
                  <Typography sx={{fontWeight:'600',fontSize:{lg:'40px',md:'26px',sm:'20px',xs:'17px'},lineHeight:{lg:'60px',md:'50px',sm:'30px',xs:'20px'},color:'#000000'}}>Capture customer attention in the SMS inbox with rich, 2-way engaging conversation</Typography>
              </Grid>
              <Grid item sm={6} xs={12} sx={{padding:{md:'30px',sm:'20px'},margin:{sm:'0px 0px',xs:'10px 0px'}}}>
                  <Typography sx={{fontWeight:'500',fontSize:{lg:'25px',md:'17px',sm:'13px',xs:'15px'},lineHeight:{lg:'40px',md:'30px',sm:'20px',xs:'18px'},color:'#000000'}}>"Differentiate your brand in the SMS inbox by featuring your brand’s name, logo, and verification badge. Empower customers to engage with your brand on RCS with rich media, carousels, suggested actions, reply buttons, and additional interactive features."</Typography>
              </Grid>
            </Grid>
        </Box>
      </Box>

      {/* ----------------- */}

      <Box sx={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',margin:{sm:'40px 0px',xs:'0px 0px'}}}>
        <Box sx={{width:'85%'}}>
        <Grid container  sx={{display:'flex',justifyContent:{sm:'space-between',xs:'center'},alignItems:'center',margin:{sm:'20px 0px',xs:'0px 0px'}}}>
                {
                  percentageData?.map((item,i)=>(
                    <Grid item key={i}  sm={3} xs={12} sx={{display:'flex',justifyContent:'flex-start',alignItems:'center',flexDirection:'column',margin:{sm:'40px 15px',xs:'10px 5px'},height:{md:'345px',sm:'250px',xs:'200px'},
                    '& >div> div > img':{width:{lg:'70px',md:'50px',sm:'35px',xs:'25px'},height:{lg:'70px',md:'50px',sm:'35px',xs:'25px'}}}}>
                      <Box component={'div'}>
                      <Box component={'div'} sx={{width:{lg:'200px',md:'140px',sm:'100px',xs:'80px'},height:{lg:'200px',md:'140px',sm:'100px',xs:'80px'},

                      // background:`url(${round_eclipse})`,
                      border:'5px solid #38B6FF',borderRadius:'50%',
                      display:'flex',justifyContent:'center',alignItems:'center'}}
                      >
                        <img src={item.img} alt="" />
                      </Box>
                      </Box>

                      <Typography sx={{fontWeight:'700',color:'#38B6FF',fontSize:{lg:'100px',md:'60px',sm:'40px',xs:'40px'},lineHeight:{lg:'121px',md:'100px',sm:'80px',xs:'50px'}}}>{item.number}</Typography>

                      <Typography sx={{fontWeight:'600',color:'#000000',fontSize:{xl:'30px',lg:'23px',md:'17px',sm:'11px',xs:'14px'},lineHeight:{xl:'36px',lg:'36px',md:'30px',sm:'20px',xs:'18px'},margin:{lg:'15px 0px'}}}>{item.header}</Typography>

                      <Typography sx={{fontWeight:'400',color:'#000000',fontSize:{xl:'30px',lg:'23px',md:'17px',sm:'11px',xs:'14px'},lineHeight:{xl:'36px',lg:'36px',md:'30px',sm:'20px',xs:'18px'}}}>{item.subHeader}</Typography>
                     
                    </Grid>
                  ))
                }
              </Grid>
        </Box>
      </Box>

      {/* --------------increse discovery------------- */}

      <Box sx={{width:'100%',margin:{sm:'110px 0px',xs:'50px 0px'},display:'flex',justifyContent:'center',alignItems:'center'}}>
        <Box sx={{width:'90%'}}>
          <Grid container sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <Grid item sm={7}>
              <Typography sx={{fontSize:{lg:'40px',md:'26px',sm:'20px',xs:'17px'},lineHeight:{lg:'60px',md:'50px',sm:'30px',xs:'20px'},fontWeight:'700',color:'#000000'}}>Increase discovery and sales with interactive conversations</Typography>

             <Box sx={{'& > p':{fontSize:{lg:'25px',md:'17px',sm:'13px',xs:'15px'},lineHeight:{lg:'40px',md:'30px',sm:'20px',xs:'18px'},fontWeight:'400',color:'#000000',margin:{sm:'30px 0px',xs:'10px 0px'}}}}>
              <Typography>Create a dynamic conversation experience using carousels, images, videos, & action buttons</Typography>

              <Typography>Personalize shopping experiences with insights from purchase histories</Typography>

              <Typography>Boost strategic upselling, cross-selling, and re-engaging, using interactive chatbots</Typography>
             </Box>

            </Grid>
            <Grid item sm={5} sx={{display:'flex',justifyContent:'center',alignItems:'center','& > img':{
              // width:'100%',
              width:{lg:'505px',md:'400px',sm:'270px',xs:'200px'},
              height:{lg:'505px',md:'400px',sm:'270px',xs:'200px'}}}}>
              <img src={intractive_conversations} alt="intractive_conversations" />
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* ------start your journey box */}
      <StartYourJourney/>
    </Layout>
  )
}

export default Home;
