import React from 'react'
import Layout from '../Components/Layout';
import { Box, Typography } from '@mui/material';
import pagenot_found from "../Assets/404_page.png";

const PageNotFound = () => {
  return (
    <Layout>
      <Box sx={{width:'100%',minHeight:'90vh',background:`url(${pagenot_found}) no-repeat`,backgroundSize:'100% 100%',backgroundPosition:'center'}}>
        
      </Box>
    </Layout>
  )
}

export default PageNotFound
