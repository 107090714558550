import React from 'react'
import Layout from '../Components/Layout'
import '../Styles/DLTRegistration.css'; // Import the CSS file for styling
import DltStep from "./DltServicestep"

export default function DltService() {
    return (
        <Layout>
 <div className="dlt-container">
      <div className="dlt-content">
        <h1 className="dlt-title">DLT Registration Mandatory for SMS Services in India</h1>
        <p className="dlt-description">
          Distributed Ledger Technology (DLT) is a blockchain-based registration system. According to TRAI (Telecom Regulatory Authority of India), telemarketers have to be registered on the DLT platform.
        </p>
        <p className="dlt-description">
          It is being issued in the public interest to control the SMS spam from various marketing firms. Earlier the bulk SMS providers were required to register with TRAI. With the updated rules, whoever wants to send promotional or transactional SMS to their customers needs to be DLT registered.
        </p>
        <div className="dlt-buttons">
          <button className="dlt-button">DLT Registration FAQ</button>
          <button className="dlt-button">TRAI Regulations</button>
        </div>
      </div>
      <div className="dlt-image">
        <img src="https://www.trai.gov.in/sites/all/themes/cmf/images/logo-trai.png" alt="TRAI Logo" className="trai-logo" />
        <img src="https://firebasestorage.googleapis.com/v0/b/rapify-b60ae.appspot.com/o/Join%20our%20online%20course%20to%20learn%20the%20fundamentals%20of%20starting%20a%20business..png?alt=media&token=3d435c8c-0891-48e7-acd8-b5147b7467ba" alt="DLT Process" className="dlt-process-image" />
      </div>
    </div>

<DltStep/>
        </Layout>
    )
}
