import unlimited_validity_img from "../Assets/unlimited_validity_img.png";
import secure_account_img from "../Assets/secure_Account_img.png";
import instant_delivery_img from "../Assets/instant_delivery_img.png";
import reliable_img from "../Assets/100%_reliable_img.png";
import instant_activation_img from "../Assets/instant_activation_img.png";
import customer_support_img from "../Assets/customer_support_img.png";
import unlimited_sender_ids_img from "../Assets/unlimited_sender_ids_img.png";
import easy_api_intigration_img from "../Assets/easy_api_intigration_img.png";
import easy_to_use_img from "../Assets/easy_to_use_img.png";

export const ramptechnoFeaturesData = [
  {
    Img:unlimited_validity_img,
    header:'Unlimited Validity',
    about:'Never worry about your SMS running out of Validity, at RAPIFY SMS we offer unlimited validity'
  },
  {
    Img:secure_account_img,
    header:'Secure Account',
    about:'We take security very seriously at RAPIFY SMS we operate with some the most secure Servers out there.'
  },
  {
    Img:instant_delivery_img,
    header:'Instant Delivery',
    about:'Your SMS content will be delivered in a blink of an eye, Guaranteed.'
  },
  {
    Img:reliable_img,
    header:'100% Reliable',
    about:'With Redundant Servers in 7 Major States, RAPIFY SMS is 100% Reliable'
  },
  {
    Img:instant_activation_img,
    header:'Instant Activation',
    about:'Create an account with RAPIFY SMS in Seconds, You can start sending those messages under 2 Min.'
  },
  {
    Img:customer_support_img,
    header:'Customer Support',
    about:'RAPIFY SMS has a strong customer Support team to cater to your needs.'
  },
  {
    Img:unlimited_sender_ids_img,
    header:'Unlimited Sender IDs',
    about:"With RAPIFY SMS you can create unlimited Sender ID's"
  },
  {
    Img:easy_api_intigration_img,
    header:'Easy API Integration',
    about:"We Provide Secure API Key to integrate with your exisiting CRM or POS"
  },
  {
    Img:easy_to_use_img,
    header:'Easy To Use',
    about:"Our Easy to use UI gives you the ability to focus your marketing efforts without hustle"
  },
]