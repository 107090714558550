import React from 'react'
import Layout from '../Components/Layout'
import { Box, Grid, Typography } from '@mui/material';
import pricing_banner from "../Assets/pricing_banner.png";
import {BulkSmsData,BulkWhatsappData,singleNumberWhatsapData} from "../Data/PricingData";
import { Link } from 'react-router-dom';
import StartYourJourney from '../Components/StartYourJourney';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Scanner from "../Assets/NewScanner.png"

const Pricing = () => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Layout>
      <Box sx={{width:'100%',minHeight:{sm:"60vh",xs:"40vh"},background:`url(${pricing_banner}) no-repeat`,backgroundPosition:'center',backgroundSize:'100% 100%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>

        <Typography variant='h3' sx={{fontWeight:'700',fontSize:{lg:'60px',md:'50px',sm:'40px',xs:'20px'},lineHeight:{lg:'90px',md:'70px',sm:'50px',xs:'30px'},color:'#43C69B'}}>Pricing</Typography>

        <Typography sx={{fontWeight:'400',textAlign:"center",fontSize:{lg:'25px',md:'20px',sm:'14px',xs:'10px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'10px'},color:'#fff'}}>Get access features that power your conversations with the right context, data,and customer insights.</Typography>


      </Box>

{/* -----------bulk sms----------------- */}
      <Box sx={{width:'100%',}}>
        <Typography sx={{textAlign:'center',fontWeight:'700',fontSize:{lg:'45px',md:'35px',sm:'25px',xs:'18px'},lineHeight:{lg:'67.5px',md:'67.5px',sm:'50px',xs:'30px'},color:'#0B0B0B',margin:{md:'30px 0px',sm:'15px 0px',xs:'10px 0px'}}}>Bulk SMS</Typography>
        </Box>
      <Grid container sx={{display:'flex',alignItems:'center',justifyContent:'space-between',padding:{lg:'0px 20px',md:'0px 20px',sm:'0px 15px'}}}>
          
            {
              BulkSmsData?.map((item,i)=>(
                <Grid item key={i} sm={2.7} xs={12} sx={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column',margin:'20px 0px','& > img':{height:{xl:'385px',lg:'320px',md:'265px',sm:'200px',xs:'160px'},width:{xl:'325px',lg:'260px',md:'200px',sm:'143px',xs:'160px'}}}}>
                  <img src={item.Img} alt="item.Img" />
                  <button className='buyNow_btn' onClick={handleClickOpen}><Link>Buy Now</Link></button>
                </Grid>
              ))
            }
          
      </Grid>

{/* ----------------bulk whtsapp------- */}

      <Box sx={{width:'100%',}}>
        <Typography sx={{textAlign:'center',fontWeight:'700',fontSize:{lg:'45px',md:'35px',sm:'25px',xs:'18px'},lineHeight:{lg:'67.5px',md:'67.5px',sm:'50px',xs:'30px'},color:'#0B0B0B',margin:{md:'30px 0px',sm:'15px 0px',xs:'10px 0px'}}}>Bulk Whatsapp</Typography>
        </Box>
      <Grid container sx={{display:'flex',alignItems:'center',justifyContent:'space-between',padding:{lg:'0px 20px',md:'0px 20px',sm:'0px 15px'}}}>
          
            {
              BulkWhatsappData?.map((item,i)=>(
                <Grid item key={i} sm={2.7} xs={12} sx={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column',margin:'20px 0px','& > img':{height:{xl:'385px',lg:'320px',md:'265px',sm:'200px',xs:'160px'},width:{xl:'325px',lg:'260px',md:'200px',sm:'143px',xs:'160px'}}}}>
                  <img src={item.Img} alt="item.Img" />
                  <button className='buyNow_btn' onClick={handleClickOpen}><Link>Buy Now</Link></button>
                </Grid>
              ))
            }
          
      </Grid>

      {/* -------single number whatsapp----------- */}

      <Box sx={{width:'100%',}}>
        <Typography sx={{textAlign:'center',fontWeight:'700',fontSize:{lg:'45px',md:'35px',sm:'25px',xs:'18px'},lineHeight:{lg:'67.5px',md:'67.5px',sm:'50px',xs:'30px'},color:'#0B0B0B',margin:{md:'30px 0px',sm:'15px 0px',xs:'10px 0px'}}}>Single Number Whatsapp</Typography>
        </Box>
      <Grid container sx={{display:'flex',alignItems:'center',justifyContent:'space-between',padding:{lg:'0px 20px',md:'0px 20px',sm:'0px 15px'}}}>
          
            {
              singleNumberWhatsapData?.map((item,i)=>(
                <Grid item key={i} sm={2.7} xs={12} sx={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column',margin:'20px 0px','& > img':{height:{xl:'385px',lg:'320px',md:'265px',sm:'200px',xs:'160px'},width:{xl:'325px',lg:'260px',md:'200px',sm:'143px',xs:'160px'}}}}>
                  <img src={item.Img} alt="item.Img" />
                  <button className='buyNow_btn' onClick={handleClickOpen}><Link>Buy Now</Link></button>
                </Grid>
              ))
            }
          
      </Grid>

      {/* ----------start yopur journey------ */}
      <StartYourJourney/>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <img src={Scanner}></img>

        <button onClick={handleClose} className="close-button">Close</button>
       
       
       
      </Dialog>
    </Layout>
  )
}

export default Pricing
