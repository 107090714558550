import React from 'react'
import Layout from '../Components/Layout';
import { Box, Typography } from '@mui/material';
import virtualWhatsapp_banner from "../Assets/virtual_whatsapp_banner.png";
import StartYourJourney from '../Components/StartYourJourney';

const VirtualWhatsapp = () => {
  return (
    <Layout>
      <Box sx={{width:'100%',minHeight:'50vh',background:`url(${virtualWhatsapp_banner}) no-repeat`,backgroundPosition:'center',backgroundSize:'100% 100%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>

<Typography variant='h3' sx={{fontWeight:'700',fontSize:{lg:'60px',md:'50px',sm:'40px',xs:'20px'},lineHeight:{lg:'90px',md:'70px',sm:'50px',xs:'30px'},color:'#43C69B'}}>Virtual WhatsApp</Typography>

<Typography sx={{fontWeight:'400',textAlign:"center",fontSize:{lg:'22px',md:'15px',sm:'10px',xs:'10px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'10px'},color:'#fff'}}>"Virtual WhatsApp" provides users with a virtual number to send bulk messages, safeguarding personal privacy while maintaining professional communication standards. </Typography>


      </Box>

      <Box  sx={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center',margin:{md:"30px 0px"}}}>
<Box sx={{'& > .sms_para':{margin:{lg:'16px 0px',md:'14px 0px',sm:'12px 0px',xs:'9px 0px'}},'& > .sms_para > p':{fontWeight:'500',fontSize:{xl:'25px',lg:'22px',md:'16px',sm:'12px',xs:'9px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'15px'}},width:'90%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
<Box className='sms_para'>
  <Typography>
  "Virtual WhatsApp" services typically offer users the ability to obtain a virtual phone number that is specifically designated for use with WhatsApp. These virtual numbers operate independently from the user's primary phone number and are often used by businesses, marketers, or individuals who require a separate channel for their WhatsApp communication.       
  </Typography>

</Box>
<Box className='sms_para'>
  <Typography>
  One of the primary advantages of using a virtual WhatsApp number is privacy and security. By utilizing a virtual number, users can communicate with customers, clients, or contacts without disclosing their personal phone number. This is particularly beneficial for businesses or professionals who wish to maintain a level of anonymity or separation between their personal and professional communications.  
  </Typography>

</Box>
<Box className='sms_para'>
<Typography>
Additionally, virtual WhatsApp numbers may offer advanced features and functionalities that enhance the user experience. For example, some services provide automation tools that allow users to schedule messages, set up auto-replies, or create chatbots to streamline communication workflows. Others offer analytics capabilities, enabling users to track the performance of their messaging campaigns, monitor engagement metrics, and gain insights into customer behaviour
</Typography>

</Box>

<Box className='sms_para'>
<Typography>
Furthermore, virtual WhatsApp numbers can be easily integrated with other platforms or services, allowing for seamless communication across multiple channels. This integration capability enables businesses to centralize their messaging efforts and improve efficiency in managing customer interactions.
</Typography>

</Box>
</Box>
     </Box>


<StartYourJourney/>
    </Layout>
  )
}

export default VirtualWhatsapp
