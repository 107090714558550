import { Box, Grid, Typography } from '@mui/material'
import React from 'react';
import "../Styles/StartYourJourneyStyles.css"
import { Link } from 'react-router-dom';
import start_your_journey_img from "../Assets/start_your_journey_img.png";

const StartYourJourney = () => {
  const handleWhatsAppClick = () => {
    const phoneNumber = '918249646500'; // WhatsApp phone number
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  };

  return (
    <>
      <Box sx={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',margin:{md:'50px 0px',xs:'20px 0px'}}}>
        <Box className='journey_Box' sx={{width:'90%',height:{lg:'450px',md:'350px',sm:'270px',xs:'150px'},borderRadius:{sm:'70px',xs:'30px'}}}>
            <Grid container>
              <Grid item xs={6} sx={{display:'flex',width:'100%',flexDirection:'column',justifyContent:'center',alignItems:'center',paddingLeft:{sm:'45px',xs:'20px'},
                
            '& > button':{width:{lg:'302px',md:'280px',sm:'160px',xs:'100px'},height:{lg:'51px',md:'40px',sm:'30px',xs:'20px'},background:'#38B6FF',border:'none',outline:'none',borderRadius:'5px',margin:'15px 0px'
           }, '& > button > a':{textDecoration:'none',color:'#FFFFFF',fontSize:{lg:'20px',md:'17px',sm:'15px',xs:'10px'}}}}>
                <Typography sx={{fontWeight:'700',fontSize:{lg:'40px',md:'28px',sm:'17px',xs:'10px'},lineHeight:{lg:'74px',md:'40px',sm:'30px',xs:'15px'}}}>Start your Journey With RAPIFY SMS Now!</Typography>
                <button onClick={handleWhatsAppClick}><Link>Contact Now</Link></button>
              </Grid>
              <Grid item xs={6} sx={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%',height:{lg:'450px',md:'350px',sm:'270px',xs:'140px'}
                ,'& > img':{width:{xL:'100%',lg:'100%',sm:'100%'},height:'100%'}}}>
                <img src={start_your_journey_img} alt="start_your_journey_img" />
              </Grid>
            </Grid>
        </Box>
      </Box>
    </>
  )
}

export default StartYourJourney
