import React from 'react'
import Layout from '../Components/Layout'
import { Box, Typography, Grid } from '@mui/material';
import transactional_sms_banner from "../Assets/transactional_sms_banner.png";
import StartYourJourney from '../Components/StartYourJourney';
export default function Refund() {
    return (
        <Layout>
            <Grid style={{ marginTop: '5%' }}>

                <Typography variant='h3' color='black' textAlign={'center'} >
                    No Refund Policy
                </Typography>
                <Typography variant='body1' color='gray' textAlign={'center'} style={{
                    marginTop: '3%', paddingLeft: '10%', paddingRight: '10%', fontSize: '20px',
                }} >At RapifySMS, we strive to provide the best possible service to our customers. However, due to the nature of our services, all sales are final, and we do not offer refunds once a purchase has been made.</Typography>



                <Typography variant='h6' color='#19DBF6' style={{ fontWeight: 'bold', marginLeft: '5%', marginTop: '3%', fontSize: '28px' }}>
                    Important Notes:
                </Typography>
                <Typography variant='h6' color='black' style={{ fontWeight: 'bold', marginLeft: '5%', marginTop: '3%', fontSize: '28px' }}>
                    Definitions:
                </Typography>
                <Typography textAlign={'justify'} style={{ paddingLeft: '5%', paddingRight: '5%', fontSize: '20px', marginTop: '2%' }}>
                    1.  &emsp; We encourage you to carefully review the service packages and features before making a purchase.
                </Typography>
                <Typography textAlign={'justify'} style={{ paddingLeft: '5%', paddingRight: '5%', fontSize: '20px', marginTop: '2%' }}>
                    2.  &emsp; If you experience any technical issues or have questions about your purchase, please contact our support team at info@rapifysms.com for assistance.
                </Typography>
                <Typography textAlign={'justify'} style={{ paddingLeft: '5%', paddingRight: '5%', fontSize: '20px', marginTop: '2%' }}>
                    3.  &emsp; In the event of service disruptions or outages, our team will work diligently to resolve the issue, but no refunds will be provided under such circumstances.
                </Typography>

                <Typography textAlign={'justify'} color='gray' style={{ paddingLeft: '5%', paddingRight: '5%', fontSize: '20px', marginTop: '2%', }}>
                    For further information, please reach out to us via email at info@rapifysms.com.
                </Typography>
            </Grid>
        </Layout>
    )
}
