import React from 'react'
import Layout from '../Components/Layout';
import { Box, Typography } from '@mui/material';
import promotional_sms_banner from "../Assets/promotional_sms_banner.png";
import StartYourJourney from '../Components/StartYourJourney';

const PromotionalSms = () => {
  return (
    <Layout>
       <Box sx={{width:'100%',minHeight:'50vh',background:`url(${promotional_sms_banner}) no-repeat`,backgroundPosition:'center',backgroundSize:'100% 100%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>

<Typography variant='h3' sx={{fontWeight:'700',fontSize:{lg:'60px',md:'50px',sm:'40px',xs:'20px'},lineHeight:{lg:'90px',md:'70px',sm:'50px',xs:'30px'},color:'#43C69B'}}>Promotional SMS</Typography>
<Typography sx={{fontWeight:'400',textAlign:"center",fontSize:{lg:'22px',md:'15px',sm:'10px',xs:'10px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'10px'},color:'#fff'}}>Promotional SMS services enable businesses to reach a wide audience with targeted offers and promotions, fostering customer engagement and driving sales.</Typography>


</Box>

<Box  sx={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center',margin:{md:"30px 0px"}}}>
<Box sx={{'& > .sms_para':{margin:{lg:'16px 0px',md:'14px 0px',sm:'12px 0px',xs:'9px 0px'}},'& > .sms_para > p':{fontWeight:'500',fontSize:{xl:'25px',lg:'22px',md:'16px',sm:'12px',xs:'9px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'15px'}},width:'90%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
<Box className='sms_para'>
  <Typography>
  Promotional SMS messaging serves as a cornerstone in the marketing arsenal of businesses and organizations, aimed at engaging customers and driving sales through targeted promotional campaigns. These messages are meticulously crafted to highlight enticing offers, discounts, new product launches, or upcoming events, aiming to capture the attention and interest of recipients.
  Central to promotional SMS is its persuasive content, strategically designed to evoke a response from recipients, whether it be making a purchase, visiting a website, or participating in a promotional event. These messages often include a clear call-to-action, guiding recipients towards the desired action and facilitating conversion.           
  </Typography>

</Box>
<Box className='sms_para'>
  <Typography>
  Moreover, the success of promotional SMS campaigns hinges on effective targeting and timing. Businesses leverage customer data and insights to segment their audience and deliver tailored promotions to specific demographics or customer segments. Additionally, timing plays a crucial role, with messages strategically scheduled to reach recipients at optimal times when they are most likely to be receptive and engage with the offer.   
  </Typography>

</Box>
<Box className='sms_para'>
<Typography>
Ensuring compliance with regulatory requirements is paramount for businesses engaging in promotional SMS messaging. This includes obtaining consent from recipients before sending promotional messages and providing mechanisms for recipients to opt out of future communications.
Throughout the campaign lifecycle, businesses closely monitor and analyse the performance of promotional SMS messages. Key metrics such as delivery rates, open rates, click-through rates, and conversion rates provide valuable insights into the effectiveness of the campaign, enabling businesses to refine their strategies and optimize future campaigns for maximum impact.
</Typography>

</Box>
</Box>
</Box>


<StartYourJourney/>
    </Layout>
  )
}

export default PromotionalSms
