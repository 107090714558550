import bulk_sms_1 from "../Assets/bulk_sms_1.png";
import bulk_sms_2 from "../Assets/bulk_sms_2.png";
import bulk_sms_3 from "../Assets/bulk_sms_3.png";
import bulk_sms_4 from "../Assets/bulk_sms_4.png";

import bulk_whatsapp_1 from "../Assets/bulk_whatsapp_1.png";
import bulk_whatsapp_2 from "../Assets/bulk_whatsapp_2.png";
import bulk_whatsapp_3 from "../Assets/bulk_whatsapp_3.png";
import bulk_whatsapp_4 from "../Assets/bulk_whatsapp_4.png";

import single_number_whatsapp_1 from "../Assets/single_number_whatsapp_1.png";
import single_number_whatsapp_2 from "../Assets/single_number_whatsapp_2.png";
import single_number_whatsapp_3 from "../Assets/single_number_whatsapp_3.png";
import single_number_whatsapp_4 from "../Assets/single_number_whatsapp_4.png";


export const BulkSmsData =[
  {
    Img:bulk_sms_1
  },
  {
    Img:bulk_sms_2
  },
  {
    Img:bulk_sms_3
  },
  {
    Img:bulk_sms_4
  },
]


export const BulkWhatsappData =[
  {
    Img:bulk_whatsapp_1
  },
  {
    Img:bulk_whatsapp_2
  },
  {
    Img:bulk_whatsapp_3
  },
  {
    Img:bulk_whatsapp_4
  },
]


export const singleNumberWhatsapData =[
  {
    Img:single_number_whatsapp_1
  },
  {
    Img:single_number_whatsapp_2
  },
  {
    Img:single_number_whatsapp_3
  },
  {
    Img:single_number_whatsapp_4
  },
]