import React from 'react'
import Layout from '../Components/Layout';
import { Box, Typography } from '@mui/material';
import greentick_banner from "../Assets/greentick_banner.png";
import StartYourJourney from '../Components/StartYourJourney';

const GreenTickVerified = () => {
  return (
    <Layout>
       <Box sx={{width:'100%',minHeight:'50vh',background:`url(${greentick_banner}) no-repeat`,backgroundPosition:'center',backgroundSize:'100% 100%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>

<Typography variant='h3' sx={{fontWeight:'700',fontSize:{lg:'60px',md:'50px',sm:'40px',xs:'20px'},lineHeight:{lg:'90px',md:'70px',sm:'50px',xs:'30px'},color:'#43C69B'}}>Green Tick Verified</Typography>

<Typography sx={{fontWeight:'400',textAlign:"center",fontSize:{lg:'22px',md:'15px',sm:'10px',xs:'10px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'10px'},color:'#fff'}}>"Virtual WhatsApp" provides users with a virtual number to send bulk messages, safeguarding personal privacy while maintaining professional communication standards.</Typography>


      </Box>

      <Box  sx={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center',margin:{md:"30px 0px"}}}>
<Box sx={{'& > .sms_para':{margin:{lg:'16px 0px',md:'14px 0px',sm:'12px 0px',xs:'9px 0px'}},'& > .sms_para > p':{fontWeight:'500',fontSize:{xl:'25px',lg:'22px',md:'16px',sm:'12px',xs:'9px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'15px'}},width:'90%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
<Box className='sms_para'>
  <Typography>
  "Green Tick Verified" status on WhatsApp represents the pinnacle of authenticity and credibility for businesses and organizations. It signifies that the associated WhatsApp Business account has undergone a meticulous verification process administered by WhatsApp, ensuring that it meets the platform's stringent standards for legitimacy and trustworthiness.      
  </Typography>

</Box>
<Box className='sms_para'>
  <Typography>
  The green tick badge, prominently displayed next to the account name, serves as a hallmark of reliability, assuring users that they are engaging with a verified entity with whom they can safely interact. 
  </Typography>

</Box>
<Box className='sms_para'>
<Typography>
This badge not only instills confidence in users but also distinguishes verified accounts from the multitude of unverified ones, offering a clear signal of authenticity amidst the digital landscape. Furthermore, "Green Tick Verified" status confers several benefits upon businesses, including enhanced visibility, increased credibility, and greater user trust.
</Typography>

</Box>

<Box className='sms_para'>
<Typography>
Verified accounts may enjoy preferential treatment in search results and recommendations, leading to heightened exposure and expanded reach among potential customers. Ultimately, "Green Tick Verified" status serves as a badge of honor for businesses, validating their commitment to transparency, authenticity, and excellence in their interactions on WhatsApp.
</Typography>

</Box>
</Box>
     </Box>


<StartYourJourney/>
    </Layout>
  )
}

export default GreenTickVerified
