import transactional_sms from "../Assets/transactional_sms.png";
import promotional_sms from "../Assets/promotional_sms.png";
import highPriority_otp_based from "../Assets/high_priority_otp_based(API).png";
import one_number_whatsapp from "../Assets/one_number_whatsapp.png";
import virtual_whatsapp from "../Assets/virtual_whatsapp.png";
import green_tick_verified from "../Assets/grren_tick_verified.png";


 export const bulkSms = [
  {
    Img:transactional_sms,
    header:"Transactional SMS",
    about:"Transactional SMS services offer instant delivery of high-priority messages, ensuring reliability, security, and compliance with regulatory requirements, ultimately improving customer engagement and operational efficiency."
  },
  {
    Img:promotional_sms,
    header:"Promotional SMS",
    about:"Promotional SMS services enable businesses to reach a wide audience with targeted offers and promotions, fostering customer engagement and driving sales."
  },
  {
    Img:highPriority_otp_based,
    header:"High Priority OTP Based (API)",
    about:"High-priority OTP-based API services ensure secure and instant delivery of one-time passwords, enhancing authentication processes and safeguarding sensitive transactions."
  },

]            

export const bulkWhatsapp = [
  {
    Img:one_number_whatsapp,
    header:"Own Number WhatsApp",
    about:'"Own Number WhatsApp" allows users to send bulk messages from their own WhatsApp number, enhancing authenticity and increasing engagement with recipients.'
  },
  {
    Img:virtual_whatsapp,
    header:"Virtual WhatsApp",
    about:'"Virtual WhatsApp" provides users with a virtual number to send bulk messages, safeguarding personal privacy while maintaining professional communication standards.'
  },
  {
    Img:green_tick_verified,
    header:"Green Tick Verified",
    about:'"Green Tick Verified" ensures messages are sent from verified WhatsApp Business accounts, instilling trust and credibility among recipients, ultimately improving message delivery and engagement.'
  },
]