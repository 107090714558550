import React, { useEffect } from 'react';

const TawkToWidget = () => {
  useEffect(() => {
    // Tawk.to script
    const tawkScript = document.createElement("script");
    tawkScript.src = "https://embed.tawk.to/66cd60f350c10f7a00a0c560/1i6945en9"; // Replace with your Tawk.to script URL
    tawkScript.async = true;
    tawkScript.charset = "UTF-8";
    tawkScript.setAttribute("crossorigin", "*");

    document.body.appendChild(tawkScript);

    return () => {
      document.body.removeChild(tawkScript); // Cleanup on component unmount
    };
  }, []);

  return null; // This component doesn't render any JSX
};

export default TawkToWidget;
// <!--Start of Tawk.to Script-->
// <script type="text/javascript">
// var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
// (function(){
// var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
// s1.async=true;
// s1.src='';
// s1.charset='UTF-8';
// s1.setAttribute('crossorigin','*');
// s0.parentNode.insertBefore(s1,s0);
// })();
// </script>
// <!--End of Tawk.to Script-->