import React from 'react'
import Layout from '../Components/Layout';
import { Box, Typography } from '@mui/material';
import highpriority_banner from "../Assets/high_priority_banner.png";
import StartYourJourney from '../Components/StartYourJourney';

const HighPriority = () => {
  return (
    <Layout>
        <Box sx={{width:'100%',minHeight:'50vh',background:`url(${highpriority_banner}) no-repeat`,backgroundPosition:'center',backgroundSize:'100% 100%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>

<Typography variant='h3' sx={{fontWeight:'700',fontSize:{lg:'60px',md:'50px',sm:'40px',xs:'20px'},lineHeight:{lg:'90px',md:'70px',sm:'50px',xs:'30px'},color:'#43C69B'}}>High Priority OTP Based (API)</Typography>

<Typography sx={{fontWeight:'400',textAlign:"center",fontSize:{lg:'22px',md:'15px',sm:'10px',xs:'10px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'10px'},color:'#fff'}}>High-priority OTP-based API services ensure secure and instant delivery of one-time passwords, enhancing authentication processes and safeguarding sensitive transactions.</Typography>


</Box>

<Box  sx={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center',margin:{md:"30px 0px"}}}>
<Box sx={{'& > .sms_para':{margin:{lg:'16px 0px',md:'14px 0px',sm:'12px 0px',xs:'9px 0px'}},'& > .sms_para > p':{fontWeight:'500',fontSize:{xl:'25px',lg:'22px',md:'16px',sm:'12px',xs:'9px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'15px'}},width:'90%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
<Box className='sms_para'>
  <Typography>
  High Priority OTP Based (API)" is a specialized messaging service designed to deliver one-time passwords (OTPs) with utmost efficiency and reliability through an application programming interface (API). This feature holds paramount importance in scenarios where instant authentication is required, such as account logins, transaction verifications, or access to secure systems.          
  </Typography>

</Box>
<Box className='sms_para'>
  <Typography>
  
The significance of OTPs in ensuring robust security measures cannot be overstated, particularly in today's digital landscape where cyber threats abound. By prioritizing the delivery of OTPs, this service ensures that users receive their unique authentication codes promptly, minimizing any delays or disruptions in the authentication process.   
  </Typography>

</Box>
<Box className='sms_para'>
<Typography>
Furthermore, the integration of an API streamlines the delivery of OTPs across various platforms and applications, allowing for seamless authentication workflows. This not only enhances the user experience but also improves the overall security posture of organizations by reducing the risk of unauthorized access or fraudulent activities.
</Typography>

</Box>

<Box className='sms_para'>
<Typography>
In essence, "High Priority OTP Based (API)" serves as a cornerstone in  bolstering security measures, providing organizations with a reliable  and efficient means of authenticating users and safeguarding sensitive  information. Its ability to deliver OTPs promptly and seamlessly via an  API underscores its importance in today's interconnected digital  landscape, where security and user trust are of paramount importance.
</Typography>

</Box>
</Box>
</Box>


<StartYourJourney/>
    </Layout>
  )
}

export default HighPriority
