import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Pricing from './Pages/Pricing';
import Home from './Pages/Home';
import Services from './Pages/Services';
import AboutUs from './Pages/AboutUs';
import ContactUs from './Pages/ContactUs';
import SignIn from './Pages/SignIn';
import PageNotFound from './Pages/PageNotFound';
import TransactionalSms from "./Pages/TransactionalSms";
import PromotionalSms from "./Pages/PromotionalSms";
import HighPriority from "./Pages/HighPriority";
import OwnNumber from "./Pages/OwnNumber";
import VirtualWhatsapp from "./Pages/VirtualWhatsapp";
import GreenTickVerified from "./Pages/GreenTickVerified";
import ScrollToTopOnPageChange from './Components/ScrollTop';
import Privacy_Policy from "./Pages/Privacy_policy";
import Terms_Services from "./Pages/Terms_services";
import DltService from "./Pages/DltService";
import TawkToWidget from "./Pages/Takiow";
import Refund from "./Pages/Refund";

const App = () => {
  const RedirectToAdmin = () => {
    React.useEffect(() => {
      window.location.href = "http://34.47.142.39:3000/";
    }, []);
  
    return null;
  };
  return (
    <>
      <BrowserRouter>
      <ScrollToTopOnPageChange/>
      <TawkToWidget />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/price' element={<Pricing />} />
          <Route path='/services' element={<Services />} />
          <Route path='/about' element={<AboutUs />} />
          <Route path='/contact' element={<ContactUs />} />
          <Route path='/signin' element={<SignIn />} />
          <Route path='*' element={<PageNotFound />} />
          <Route path='/transactional_sms' element={<TransactionalSms />} />
          <Route path='/promotional_sms' element={<PromotionalSms />} />
          <Route path='/high_priority_otp_based' element={<HighPriority/>} />
          <Route path='/own_number' element={<OwnNumber/>} />
          <Route path='/virtual_whatsapp' element={<VirtualWhatsapp/>} />
          <Route path='/green_tick_verified' element={<GreenTickVerified/>} />
          <Route path='/privacy_policy' element={<Privacy_Policy/>} />
          <Route path='/privacy_policy' element={<Privacy_Policy/>} />
          <Route path='/terms_services' element={<Terms_Services/>} />
          <Route path='/dlt_service' element={<DltService/>} />
          <Route path='/refund_policy' element={<Refund />} />

        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App

