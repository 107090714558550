import React from 'react';
import { useState } from 'react';
import Layout from '../Components/Layout'
import { Box, Typography,Grid } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useMemo } from 'react';

const ContactUs = () => {
  // const [name, setName] = useState('');
  // const [phoneNumber, setPhoneNumber] = useState('');
  // const [email, setEmail] = useState('');
  const [age, setAge] = React.useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    option: ''
  });
  const whatsapp = 'Whatsapp';
const sms = 'SMS';

  
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.name || !formData.email || !formData.number || !formData.option) {
      alert('Please fill in all fields.');
      return;
    }
    showPleaseWaitMessage();
  
    const formDataToSubmit = new FormData();
    formDataToSubmit.append('name', formData.name);
    formDataToSubmit.append('number', formData.number);
    formDataToSubmit.append('email', formData.email);
    formDataToSubmit.append('option', formData.option);
  
    fetch('https://script.google.com/macros/s/AKfycbx-9geeXeTRL9HOu8Y-KanDccgSZo6EXU4tggwnYVaXvUdgYvPuZA-AGb_W0LpThjES/exec', {
      method: 'POST',
      body: formDataToSubmit
    })
    .then(response => response.text())
    .then(data => {
      console.log(data); // Handle response from server if needed
      alert('Form submitted successfully!');
      setFormData({ name: '', number: '', option: '', email: '' });
      // setShowForm(false)
    })
    .catch(error => {
      console.error('Error:', error);
      alert('An error occurred while submitting the form.');
    })
    .finally(() => {
      hidePleaseWaitMessage();
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'option') {
      setFormData({
        ...formData,
        option: value
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };
  const memoizedFormData = useMemo(() => ({
    name: formData.name,
    email: formData.email,
    number: formData.number,
    option: formData.option
  }), [formData]);
  const showPleaseWaitMessage = () => {
    const submitButton = document.getElementById('submitButton');
    submitButton.style.width = '8vw';

    submitButton.innerText = 'Please wait...';
    submitButton.disabled = true;
  };

  const hidePleaseWaitMessage = () => {
    const submitButton = document.getElementById('submitButton');
    submitButton.innerText = 'Submit';
    submitButton.disabled = false;
  };
  
  return (
    <Layout>

<Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
      <Box sx={{
          width: '100%',
          height: '90%', // Set the height for the iframe container
          margin: '5% auto',
          padding: '3%',
          border: '1px solid black',
          borderRadius: '60px !important',
          position: 'relative', // Add position relative to contain iframe
          overflow: 'hidden', // Hide overflow for iframe
          
           
            border: 'none', // Remove border from iframe
          
        }}>
      <p><iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14972.717802495046!2d85.8273014!3d20.2513911!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a19a7f302cb02bf%3A0xe2758fbe83583c03!2sRapify%20Sms!5e0!3m2!1sen!2sin!4v1715319386756!5m2!1sen!2sin"  loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"  style={{width:'100%',height: '400px'}}></iframe></p>
      </Box>
      </Grid>
      <Grid item xs={12} sm={6}>

      <Box sx={{
          width:{sm:'520px',xs:'300px'},
          margin:"5% auto",
          padding:"3%",
          border:"1px solid black",
          borderRadius:"60px !important"
        }}>
          <Typography  variant='h5' sx={{fontSize:{sm:'1.5rem',xs:'1.3rem'},padding:{sm:'0px',xs:'10px'}}} fontWeight={"bold"}>Contact Us</Typography>
          <form >
            <Box mt="3%" sx={{"& >label > input":{width:'100%',height:{sm:'40px',xs:'30px'},outline:'none',padding:{sm:'10px',xs:'8px'},borderRadius:'10px',border:'1px solid #0B0B0B'}}}>
              <label>
                <Typography fontWeight={"bold"}>Name</Typography>
                <input 
                 
                  type="text" 
                  name="name"
                  required 
                  value={memoizedFormData.name} onChange={handleChange}
                />
              </label>
            </Box>
            <br />
            <Box mt="3%" sx={{"& >label > input":{width:'100%',height:{sm:'40px',xs:'30px'},outline:'none',padding:{sm:'10px',xs:'8px'},borderRadius:'10px',border:'1px solid #0B0B0B'}}}>
              <label>
                <Typography fontWeight={"bold"}>Email I'D</Typography>
                <input 
                  
                  type="email" 
                 
                  name="email"
                  required 
                  value={memoizedFormData.email} onChange={handleChange}
                />
              </label>
            </Box>
            <small>Format: xyz@gmail.com</small>
            <br/>
            <Box mt="3%" sx={{"& >label > input":{width:'100%',height:{sm:'40px',xs:'30px'},outline:'none',padding:{sm:'10px',xs:'8px'},borderRadius:'10px',border:'1px solid #0B0B0B'}}}>
            <label>
            <Typography fontWeight={"bold"}>Phone Number</Typography>
              <input 
                type="number" 
                name="number"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" 
                value={memoizedFormData.number} 
                onChange={handleChange}
                required 
                />
              </label>
            </Box>
            <small>Format: 123-456-7890</small>
            <br />
            <Box mt={"3%"}>
            <Typography fontWeight={"bold"}>Select Option</Typography>
            <FormControl fullWidth >
              <Select
               name='option'
               value={memoizedFormData.option}  
               onChange={handleChange}
                sx={{height:"40px",borderRadius:'10px',border:'1px solid #0B0B0B'}}
              >
                <MenuItem value={whatsapp}>{whatsapp}</MenuItem>
                <MenuItem value={sms}>{sms}</MenuItem>
              </Select>
            </FormControl>
            </Box>
            <br/>
            <button type='submit' style={{color:"#FFFFFF",margin:"auto",display:"flex",alignItems:"center",justifyContent:"center"}}  className='signin_btn' id="submitButton" onClick={handleSubmit}>Submit</button>
        </form>
      </Box>
</Grid>
      </Grid>
     </Layout>
  )
}

export default ContactUs
