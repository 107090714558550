import React from 'react'
import Layout from '../Components/Layout';
import { Box, Typography } from '@mui/material';
import ownNumber_banner from "../Assets/own_number-whatsapp_banner.png";
import StartYourJourney from '../Components/StartYourJourney';

const OwnNumber = () => {
  return (
    <Layout>
        <Box sx={{width:'100%',minHeight:'50vh',background:`url(${ownNumber_banner}) no-repeat`,backgroundPosition:'center',backgroundSize:'100% 100%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>

<Typography variant='h3' sx={{fontWeight:'700',fontSize:{lg:'60px',md:'50px',sm:'40px',xs:'20px'},lineHeight:{lg:'90px',md:'70px',sm:'50px',xs:'30px'},color:'#43C69B'}}>Own Number WhatsApp</Typography>

<Typography sx={{fontWeight:'400',textAlign:"center",fontSize:{lg:'22px',md:'15px',sm:'10px',xs:'10px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'10px'},color:'#fff'}}>"Own Number WhatsApp" allows users to send bulk messages from their own WhatsApp number, enhancing authenticity and increasing engagement with recipients.</Typography>


      </Box>

      <Box  sx={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center',margin:{md:"30px 0px"}}}>
<Box sx={{'& > .sms_para':{margin:{lg:'16px 0px',md:'14px 0px',sm:'12px 0px',xs:'9px 0px'}},'& > .sms_para > p':{fontWeight:'500',fontSize:{xl:'25px',lg:'22px',md:'16px',sm:'12px',xs:'9px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'15px'}},width:'90%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
<Box className='sms_para'>
  <Typography>
  "Own Number WhatsApp" is a feature or service that offers users the ability to send messages through WhatsApp using their own personal or business phone number as the sender ID. This feature stands out for its capacity to enhance authenticity and brand consistency in communications by ensuring that messages appear to be sent from the user's recognized phone number.         
  </Typography>

</Box>
<Box className='sms_para'>
  <Typography>
  This means that when recipients receive messages, they immediately associate them with the sender's familiar contact information, which fosters a sense of trust and credibility. Beyond mere authenticity, leveraging one's own phone number in WhatsApp messaging enables users to reinforce brand recognition and identity, as messages become inherently linked to the user's established contact details.   
  </Typography>

</Box>
<Box className='sms_para'>
<Typography>
This personalized approach not only strengthens brand loyalty but also contributes to a more engaging and meaningful communication experience for recipients. Additionally, using one's own phone number simplifies the communication process, eliminating the need to manage multiple numbers or accounts for sending messages via WhatsApp. Furthermore, by adhering to WhatsApp's policies and guidelines, "Own Number WhatsApp" ensures compliance with industry regulations, offering users peace of mind and confidence in their messaging endeavors.
</Typography>

</Box>

<Box className='sms_para'>
<Typography>
Overall, "Own Number WhatsApp" represents a powerful tool for businesses and individuals alike, enabling them to communicate effectively while maintaining authenticity, brand consistency, and compliance with WhatsApp's regulations.
</Typography>

</Box>
</Box>
     </Box>


<StartYourJourney/>
    </Layout>
  )
}

export default OwnNumber
