import React from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { useMemo } from "react";
const SignIn = () => {
  const [formData, setFormData] = useState({
    name: "",
    orgname: "",
    mob: "",
    emailid: "",
    password: "",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !formData.name ||
      !formData.emailid ||
      !formData.mob ||
      !formData.password ||
      !formData.orgname
    ) {
      alert("Please fill in all fields.");
      return;
    }
    showPleaseWaitMessage();
    const formDataToSubmit = new FormData();
    formDataToSubmit.append("name", formData.name);
    formDataToSubmit.append("mob", formData.mob);
    formDataToSubmit.append("emailid", formData.emailid);
    formDataToSubmit.append("password", formData.password);
    formDataToSubmit.append("orgname", formData.orgname);

    fetch(
      "https://script.google.com/macros/s/AKfycbxaInnqlqW2AVqmVLICPZAp21edwWanVTjV9hxO0pyVQHwH7NJqVJsjZR2rk6aAZnau/exec",
      {
        method: "POST",
        body: formDataToSubmit,
      }
    )
      .then((response) => response.text())
      .then((data) => {
        console.log(data); // Handle response from server if needed
        alert('Register successfully Click Ok!');
        setFormData({ name: '', mob: '', password: '', emailid: '', orgname: '' });
        // setShowForm(false)
        window.open('https://drive.google.com/file/d/1KCIKo8qfF7XGmxXkr3LrMbefr3hc1c-9/view?usp=sharing', '_blank');
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("An error occurred while submitting the form.");
      })
      .finally(() => {
        hidePleaseWaitMessage();
      });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const memoizedFormData = useMemo(
    () => ({
      name: formData.name,
      orgname: formData.orgname,
      emailid: formData.emailid,
      mob: formData.mob,
      password: formData.password,
    }),
    [formData]
  );
  const showPleaseWaitMessage = () => {
    const submitButton = document.getElementById('submitButton');
    submitButton.style.width = '12vw';

    submitButton.innerText = "Please wait...";
    submitButton.disabled = true;
  };

  const hidePleaseWaitMessage = () => {
    const submitButton = document.getElementById("submitButton");
    submitButton.innerText = "Submit";
    submitButton.disabled = false;
    submitButton.style.width = "10vw";
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <NavLink to={"/"}>
        <Typography
          sx={{ marginBottom: "2%" }}
          sx={{
            width: "100%",
            fontSize: "2em !important",
            fontWeight: "bolder !important",
            lineHeight: "51.43px",
            textAlign: "center",
            background: "linear-gradient(90deg, #38B6FF 0%, #43C69B 100%)",
            "-webkit-background-clip": "text",
            "-webkit-text-fill-color": "transparent",
          }}
        >
          RAPIFY SMS
        </Typography>
      </NavLink>
      <Box
        display={"flex"}
        width={"20vw"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        border={"1px solid black"}
        borderRadius={"60px"}
        sx={
          ({ height: "30%" },
          { width: { sm: "40%", xs: "100%" }, padding: { xs: "10px 0px" } })
        }
        mt={"5%"}
      >
        <Typography
          sx={{ marginBottom: "2%" }}
          variant="h5"
          textAlign={"center"}
        >
          Register
        </Typography>
        <Box mt="3%">
          <Typography sx={{ marginBottom: "2%" }} color={"black"}>
            Name
          </Typography>
          <TextField
            id="outlined-basic"
            required
            name="name"
            value={memoizedFormData.name}
            onChange={handleChange}
            sx={{
              "& .MuiOutlinedInput-root": {
                height: 40,
                width: { md: "420px", sm: "282px", xs: "220px" },
              },
            }}
          />
        </Box>
        <Box mt="3%">
          <Typography sx={{ marginBottom: "2%" }} color={"black"}>
            Organization Name
          </Typography>
          <TextField
            id="outlined-basic"
            name="orgname"
            required
            value={memoizedFormData.orgname}
            onChange={handleChange}
            sx={{
              "& .MuiOutlinedInput-root": {
                height: 40,
                width: { md: "420px", sm: "282px", xs: "220px" },
              },
            }}
          />
        </Box>
        <Box mt="3%">
          <Typography sx={{ marginBottom: "2%" }} color={"black"}>
            Phone Number
          </Typography>
          <TextField
            id="outlined-basic"
            name="mob"
            type="number"
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            value={memoizedFormData.mob}
            onChange={handleChange}
            required
            sx={{
              "& .MuiOutlinedInput-root": {
                height: 40,
                width: { md: "420px", sm: "282px", xs: "220px" },
              },
            }}
          />
        </Box>
        <Box mt="3%">
          <Typography sx={{ marginBottom: "2%" }} color={"black"}>
            Email I'D
          </Typography>
          <TextField
            id="outlined-basic"
            type="email"
            name="emailid"
            required
            value={memoizedFormData.emailid}
            onChange={handleChange}
            sx={{
              "& .MuiOutlinedInput-root": {
                height: 40,
                width: { md: "420px", sm: "282px", xs: "220px" },
              },
            }}
          />
        </Box>
        <Box mt="3%">
          <Typography sx={{ marginBottom: "2%" }} color={"black"}>
            Password
          </Typography>
          <TextField
            id="outlined-basic"
            type="password"
            name="password"
            required
            value={memoizedFormData.password}
            onChange={handleChange}
            autoComplete="new-password"
            sx={{
              "& .MuiOutlinedInput-root": {
                height: 40,
                width: { md: "420px", sm: "282px", xs: "220px" },
              },
            }}
          />
        </Box>
        {/* <Typography sx={{marginBottom:'2%'}} textAlign={"end"} sx={{color:"grey",width:"73%"}}>Forget Password?</Typography> */}
        <Button
          sx={{
            background: "#38B6FF !important",
            mt: "3%",
            width: "200px",
            color: "#FFFFFF",
          }}
          className="signin_btn"
          id="submitButton"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default SignIn;
