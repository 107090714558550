import React from 'react'
import Layout from '../Components/Layout'
import '../Styles/DLTRegistrationstep.css'; // Import the CSS file for styling
import Entity from "../Assets/image.png"
import Sender from "../Assets/sender.png"
import Content from "../Assets/content.png"
import Platfrom from "../Assets/platfrom.png"

export default function DltService() {
    return (
      <>
      
      <div className="rapify-dlt-container">
      <h2 className="rapify-dlt-title">Steps To Register on DLT Platform</h2>
      <p className="rapify-dlt-subtitle">Entities not on the DLT platform may not be able to send messages</p>
      
      <div className="rapify-dlt-steps">
        {/* Step 1 */}
        <div className="rapify-dlt-step">
          <div className="rapify-icon">
            <img src={Entity} alt="Entity Icon" />
          </div>
          <h3>Step 1: Entity Registration</h3>
          <p>
            This includes details of KYC (Know Your Customer) documents like GST, PAN, TAN, CIN etc. 
            The process has been designed for easy and quick completion. Your organisation will get a unique Entity ID.
          </p>
        </div>

        {/* Step 2 */}
        <div className="rapify-dlt-step">
          <div className="rapify-icon">
            <img src={Sender} alt="Sender Icon" />
          </div>
          <h3>Step 2: Sender ID (Header) Registration</h3>
          <p>
            Messages are classified into Transactional, Promotional, Service Implicit and Service Explicit. 
            Entities need to register all their header on the platform. Every header gets a unique Sender ID.
          </p>
        </div>

        {/* Step 3 */}
        <div className="rapify-dlt-step">
          <div className="rapify-icon">
            <img src={Content} alt="Template Icon" />
          </div>
          <h3>Step 3: Content Template Registration</h3>
          <p>
            Once a template is created, the entity retains the template ownership across all platforms. 
            Every template gets a unique Template ID that is shared across other Blockchain DLT SMS platforms seamlessly.
          </p>
        </div>

        {/* Step 4 */}
        <div className="rapify-dlt-step">
          <div className="rapify-icon">
            <img src={Platfrom} alt="Approval Icon" />
          </div>
          <h3>Step 4: DLT Platform Templates Registered</h3>
          <p>
            Once your template gets approved from DLT platform, Kindly Email all approved templates 
            in Excel format at info@rapifysms.com.
          </p>
        </div>
      </div>
    </div>

    <div className="ramtechno-dlt-container">
      <h2 className="ramtechno-dlt-title">DLT Operators List</h2>
      <p className="ramtechno-dlt-description">
        You can register any of the below operators as principal Entity to get your Sender ID (headers), templates approved
      </p>

      <div className="ramtechno-dlt-grid">
        <div className="ramtechno-dlt-operator">
          <a href="https://dltconnect.airtel.in/" target="_blank" rel="noopener noreferrer">
            <img src="https://s3-ap-southeast-1.amazonaws.com/bsy/iportal/images/airtel-logo-white-text-horizontal.jpg" alt="Airtel" />
            <span>AIRTEL</span>
          </a>
        </div>
        <div className="ramtechno-dlt-operator">
          <a href="https://trueconnect.jio.com/#" target="_blank" rel="noopener noreferrer">
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAAAbFBMVEUKKIX///8AAHwAHoIAEH4AJIQAIYMAGoEADX4AHIEAAHi5utEAE39MWZq/wdeeosNob6UlOYxJVJjS1OPv8PXJy93i4+yQlbtrc6d3fa2GjLU/S5QuPY339/oNK4ausMwgMol+hLFXYZ4zQo9+uff4AAACvUlEQVRIiZ2X25qCIBCAceQkslkeU1er7f3fcYFBy0Lrcy7KlF+GOUeikNRpc85P43jKz01aB5eQ91tVq4EKzogRxgUF2VZfgFkMCXmRBHj2AcyUfqVQtMo2wGKkYcwKHYs1sFNsnTPnVV0QHPqN7fym/fAOliT+xBGzpHwFy5h/5gjhcbkEh/ErzpDjsAD7N9+tSdI/g91HuzyEdg+wUN9zhKhiBseg/2IRtjMbJzALKcrksTvK4Btp5kEIcVdr9/I3SAKC2SKumQSgjMiDM0ElQ6DOHLjwIL8fiiK9MsD0LUPamFUWrBbP5IDr/Y4r9obKgO2z7/mfD4j4172hDwdU0hpwcYr4iOAP58kt69hK4DMakRrCoMl5pSY/CaD2Q882hpqkNAzKphzKzKmjWVNbxevLaVKPpqQRQVDjhTUAPCV+5vUTDTnzIAgX92308VdeDkjyM8lZGExxnRRttJDOachyciLboPKJW1T+Ao95IuM2CGfEBIDEen5zfh8/gcqdcLBpwmDwx3bgB1VVhV/2oXQvKQBVnYwjnAYJahblSxB30a4LuIgxxvHuUF1rb9AGwV5N4OGxC7iXuLg37sAAEDebl6Cu3nSxnEDsNTllTGN9u2gMANQCXJ2tpx5aA52smuOd2/V6w6s/jiGHQQ5LLzdiAiW8NGS/vvZpBcPzw0GTGeT9EsydTeSUyOK5g0U/sbegrUc+3L2cXYFyiYyl4ykF6l7YKud0OBlnifvcUYs7FjZXOiLMcq3bS1VUhywHpwwnt6bDVsTg3qRFcWju4FOJL8pjrCUoKeY0E0JMmcOEVCDnn748BgvypviCHG4BGzK3gJWmsyaPprO/ze1urPtbeTSQb4cHshwedo8rhhy/GJCS8W1A2j+SRbuHwGj/2GmjD9YGXdgadB3Kd43WVqqW7hnmnXzx9+EfzXomg45aMR8AAAAASUVORK5CYII=" alt="Jio" />
            <span>JIO</span>
          </a>
        </div>
        <div className="ramtechno-dlt-operator">
          <a href="https://www.vilpower.in/" target="_blank" rel="noopener noreferrer">
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAAAnFBMVEX////uJzfuJjb/xgDuGy70fYX7ys796uv2o6f3srXtECfuIjPuHzH96OrvNkTtFSrvMED94uT80tbzdHz+8vPxTlrwSFT0g4ryZ3D6w8f1nqLyXGjtAyHzb3j93eD4pq34ubz2lp7wV2DwQU71j5X/8b//6qP/7Kz/+N7/9Mn/007/yyz/zz3/447/++r/3Xb/3GP/8s//5Ib/5a31h75FAAABy0lEQVRIie2U2XaCMBBAI2IUkEXABcQF61bRtur//1uLZCYRm3Dikw/OW4a5JDeZhJB3vFq4PQhLTCcpy6ZzQgZQkiYxVoSe7d/CDkRwmrFsRgmhNqvJoi6fcdZv3cIw5gIYOFXWGf5lqWFUI7PDwTj1AVxwLuSlrgQkicmyTsSTOZTapfn/YDiCVY14cuLAMhIpOIhMVuSFmPxAxVAKEpQMUly+JyrKwCWkDZTMWyzl3zZMAtIhW1d/9aAYLBUgSjojJhmvQHEVKkCS4+6wk0xgb1ihDLQCg+39mP0pgMJqu2Qgl5xUPdwBRc9Sgu4MJIe0HHenfVCkSpCs7yUT6CWTXQYpuACn/rocpvdDBZig5Kz8MIaLxhTloDsBqVJyMKkpykFhjoUwvzmLm8AeHlwuGo9JE4gnaUYuWfvC9A1gLEhyxWHYCPJeCZZ0VOsjJYiSfs9q1TpXCfJpoqh+V5Qg3kDDg1cDb6cSJJFdjwzfg/IlZ7nIrYOh9RAJfnQxR+M6+IKx2e72T2Cfh6Iojqcv7enaVXzrkm2InR53QvCo5/mDYPusBR6eBS/IFXrgHkHdA9ky7qDdA7tSs7g80Tvn6/W60W6cd2jEL2xEIptCqhdPAAAAAElFTkSuQmCC" alt="VI" />
            <span>VI</span>
          </a>
        </div>
        <div className="ramtechno-dlt-operator">
          <a href="https://www.ucc-bsnl.co.in/" target="_blank" rel="noopener noreferrer">
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAAAwFBMVEX///+en6MASocAU4wAVItrjaxXgaV4lrLX4OcqZZIAV44AWY6XmJwATYgAR4ScoaWko6Xw9ffExMYAQoHy8vLj5OW4foLvEBiYpankAABzk7LBz9nm7O+RqsBSfKHd5eoAPH3S09Srq66musoANHuokZa7eX20gobYTVDxAA3lLTPqGiHiNToTW4vfO0DSU1c9bJPFbG/LXmKPmqR9j5+3uLpthp3oa21ZeZfspaf33NzqgoTnYGL15ebrsbLrx8hFOtnyAAACeUlEQVRIie2W23KbMBCGV0gYIyGBLBwcc6pN0timJLRJekia5v3fqisl04vUtdXOtL3JPwwgRt/sURIAr/pvavUfUXUUvvEn56vZbJUDVOUUR97giiQJXsl67oZd7Mmtk9SKEJLMcFidlZ5cev725OTk4pnUbOLHzRKSbrbFdrcgllzF1C/CPCEkJX3fv3NgOpipp0E7+7LvCwemQ9D5cbAmZHFV9LsNupqlAx1nnmBCFu+3xY4sPqTZtWADufUF04ttcUmwHNk15+KarD3B9LwoNos0G9MbKuhN5m3xdre9WqQkiwcu6McM6+EJfnIcglzwzxkWMvfjviBn6+dA2zueId6d3rfJM8gZpsbXYP/V9ZwtvevzZO7paG/vq8ShGB/x5OD0zt4f8pldjUnmm1B4OMUovz3a13yee0bntLm8f/yN6a/6K6qXVrXblcoGl31YAehwGYJ71NAtw3YfGEoqA0kN7kudYVJRGlSgDVX4oTKygaXcv9eFjNZdRAUDYJx2VS1pCFoJPiKocEOOaPAL0H4feaC14fag4HRwoJr6gA1TCAqJ8S2bCYK8kcujIMaOCxdgQrl4OmO0ojWTOj8MsmbCeIy0HqgImuoJ7BrZgTwICswqH52pUHGXTmuxkwOwg6Dsyo5yYy1BiUaxMghGIFU70GPJqSk6Np3iWySF0doFF9TjUbCTtK7OTAO2MrJEsMGcxhMvi1hHLDpE7BnELMeHYyyrkgnVAhWyhIpz0z6BpRIOlGWLql6ClAcqYAE2dW24lJIHtuWo9TpmzlUulVLm5d9AFFuN7uztBhMoWduKxhM3jkOIBjcjjvb5+0P6J49e9U/0HQybM+1mygENAAAAAElFTkSuQmCC" alt="BSNL" />
            <span>BSNL</span>
          </a>
        </div>
        <div className="ramtechno-dlt-operator">
          <a href="https://www.ucc-mtnl.in/" target="_blank" rel="noopener noreferrer">
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAAAolBMVEX///8AAAD5+fn6+vocGxfS4trz8/JcXFsYFxOSkpHk5OPU1NQlJSIAZwsAYgASEArMzMzt7ezGxsXv9/MAaR4Acy9JkWUAeDmjxbOYvaxQT07h7ukefktsooJOkGgAbycxh1Szzr+BtJltqogMc0GLt58Idj2YvqfI2tG4zsRkmXo+jmBcnXV2q4t+q5NamHWtraxxcW+5uLc5ODVEREGioaEWnaKHAAAB8UlEQVRIie3R63KqMBAA4CyEELWQakG5lesRtaUKqO//amcD9HQ84EzGf51xB7JJ2I9AQpj2UDCikYdCf8In/N3w7SaUYRg5XSRJn99VYZy46Xq9jjabCFPqWpkizIsilMl1fUxGUajCPztPwm1ZShjudntFSMjBWMcf28/PPI7jt8NUxTQ8lvuq+tpV1baqqrA8qsJTqR+iNM6jqEjTMtTLkxo8lbgjx3zn5Vu8jniME3ICZjbuRZZs0nfXrdyNg/u0t0f7OgFjWWrkcrkcrxxXDK21AowkzGynrBynchIbR6ETqcDEM4yD73sZ3r7v7w3DS5SgY9m2bVmWvGXCgdKKoTERoQJUix4uA9MM5oQwmZev2HYR1ATbKxY0sj8PzGBxC+cgBNSEtDLPV9h2ATNCscXiM2B/AQJe/4eUChNfLCiFZQuAYwqy+IVTcUbIO0gnIKdAiOCY5oRpOAGtzjQJKZr7kF9wEssvHCH+NNas5HO5In6EKe6ueG5gdoXAHEFhgjCb+5DXcDHhOl4RVqYQnN6FYgmcw+JlDGvWbVUPR8eBWyNfS+gEJDX8g7O2bWt2CxshGtQTkFzEAOUZQVfxDQHw9LFmmF5i7iDma/9cwiF+IGGMyWbo9BP6kLXvpLMhfv7xgXjCJ/w9kOkPhfYXse4ttusRtuAAAAAASUVORK5CYII=" alt="MTNL" />
            <span>MTNL</span>
          </a>
        </div>
        <div className="ramtechno-dlt-operator">
          <a href="https://smartping.live/entity/login" target="_blank" rel="noopener noreferrer">
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAAfCAMAAACF8f6iAAAAkFBMVEX///9grzcAAADu7u7c3Nw9PT1ZrCufn5/T09PX19ddrjPn8uNVqyRPqBYsLCxEREQYGBi8vLxNTU3d7NdLpwrLy8tSUlKnp6cTExPDw8Pn5+d8fHyJiYk2NjYjIyOXyIN5ulvv9u3I4b+32Kqt056mz5Zzt1OHwG7T5strs0eBvWaNw3ZcXFyzs7NsbGyRkZH6G04tAAABkElEQVQ4jd2S3ZKCMAyF09BCQSigKNpi/UFdXUXf/+22hXVA98Lq5eaiGdp8c5ITAF7Giqwnr6v+xtonUbFxKh1tB3WjhBDi1y7croiLvrAD9y56haks+qkOkQF3LoK+AaP1g2S8dQD3FiRJf1HHJHExp27BeLCBg//lwHVuPPh4LNz2eIrskOfBzcGJ69wh8bAJN3CTPA/pGt+R686f4mglo+h9sLPHaelPMWklTx9Inq2x/uoDkrTNniej+s2GN3HrbJzEr2ufyJNFI8ef7SHqQ5GsHf+Z/xqXS3tqD+l0KYSYcYAmFUIHUGZZJnKAkgmRLeA6z+ZjCfecowfgYWDABXLOK5Q5NjQMPY6cUvNcYsP5FBSjVCr9mxkANgABQgtacVFexK2qqrBc2k8M2a1tbRyYg2OXQ1Oqxub12oO6vOpKKcXvYNqBsyFoMlAMKqQdSCm9obfARkrqTTGUpm2vwZzSEFQqpVRZD0KJWW7HpDxljM1CgHzM2DKAQGi9zK1XjIkpXLXWMwnKjAY0/QFO+xnpR2B4QgAAAABJRU5ErkJggg==" alt="Videocon" />
            <span>VIDEOCON</span>
          </a>
        </div>
        <div className="ramtechno-dlt-operator">
          <a href="https://telemarketer.tatateleservices.com:8082/" target="_blank" rel="noopener noreferrer">
            <img src="https://tatainternational.com/wp-content/themes/til/images/logo-tata.png" alt="Tata" />
            <span>TATA</span>
          </a>
        </div>
      </div>
    </div>
      </>
    )
}
