import React from 'react'
import Layout from '../Components/Layout'
import { Box, Typography } from '@mui/material';
import transactional_sms_banner from "../Assets/transactional_sms_banner.png";
import StartYourJourney from '../Components/StartYourJourney';

const TransactionalSms = () => {
  
  return (
    <Layout>
      <Box sx={{width:'100%',minHeight:'50vh',background:`url(${transactional_sms_banner}) no-repeat`,backgroundPosition:'center',backgroundSize:'100% 100%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>

        <Typography variant='h3' sx={{fontWeight:'700',fontSize:{lg:'60px',md:'50px',sm:'40px',xs:'20px'},lineHeight:{lg:'90px',md:'70px',sm:'50px',xs:'30px'},color:'#43C69B'}}>Transactional SMS</Typography>

        <Typography sx={{fontWeight:'400',textAlign:"center",fontSize:{lg:'22px',md:'15px',sm:'10px',xs:'10px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'10px'},color:'#fff'}}>Transactional SMS services offer instant delivery of high-priority messages, ensuring reliability, security, and compliance with regulatory requirements, ultimately improving customer engagement and operational efficiency.</Typography>

      </Box>

      <Box  sx={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center',margin:{md:"30px 0px"}}}>
      <Box sx={{'& > .sms_para':{margin:{lg:'16px 0px',md:'14px 0px',sm:'12px 0px',xs:'9px 0px'}},'& > .sms_para > p':{fontWeight:'500',fontSize:{xl:'25px',lg:'22px',md:'16px',sm:'12px',xs:'9px'},lineHeight:{lg:'37.5px',md:'25px',sm:'18px',xs:'15px'}},width:'90%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
      <Box className='sms_para'>
          <Typography>
          Transactional SMS messages serve as essential communication channels for businesses to provide customers with timely and pertinent information regarding their transactions or interactions. These messages are distinct from promotional SMS, as their primary function is to deliver critical updates rather than marketing content. Transactional SMS encompasses a wide range of communication types, including order confirmations, shipping and delivery notifications, appointment reminders, account alerts, and one-time passwords (OTPs) for authentication purposes.           
          </Typography>
  
      </Box>
      <Box className='sms_para'>
          <Typography>
          One of the key characteristics of transactional SMS is its high priority and instant delivery. These messages are typically triggered by specific events in real-time, ensuring that customers receive important updates promptly. This instant delivery is crucial for keeping customers informed and engaged throughout their transactional journey.    
          </Typography>
        
      </Box>
      <Box className='sms_para'>
        <Typography>
        Moreover, transactional SMS messages are highly reliable, with minimal chances of being blocked or filtered as spam. Businesses rely on the reliability of transactional SMS to ensure that customers receive critical information accurately and without delay. Security is also paramount, as transactional SMS often contain sensitive information such as OTPs or account details. Businesses must prioritize the security and privacy of these messages to safeguard customers' personal and financial information.
        </Typography>
        
      </Box>
      </Box>
      </Box>


      <StartYourJourney/>
    </Layout>
  )
}

export default TransactionalSms
