import React from 'react';
import Layout from '../Components/Layout';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
const Privacy_Policy = () => {
    return (
        <Layout>
        <Grid  style={{marginTop:'5%'}}>
            
            <Typography variant='h3' color='black' textAlign={'center'} >
            Privacy Policy for Rapify SMS
            </Typography>
            <Typography variant='body1' color='gray' textAlign={'center'} style={{marginTop:'3%',paddingLeft:'10%',paddingRight:'10%',fontSize:'20px',
            }} >
            At Rapify SMS, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our services.
            </Typography>
            <Typography variant='h6' color='#19DBF6' style={{fontWeight:'bold',marginLeft:'5%',marginTop:'3%',fontSize:'28px'}}>
            
            Information We Collect
            </Typography>
            <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'1%'}}>
            We may collect personal information such as your name, email address, phone number, and any other information you provide when you register or use our services. Additionally, we may collect non-personal information such as device information, log data, and usage statistics.
            </Typography>

            <Typography variant='h6' color='#19DBF6' style={{fontWeight:'bold',marginLeft:'5%',marginTop:'3%',fontSize:'28px'}}>
            How We Use Your Information
            </Typography>
            <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'2%'}}>
                1.  &emsp; To provide and maintain our services.
                </Typography>
                <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'1%'}}>
                2.  &emsp; To personalize your experience and improve our services.
                </Typography>
                <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'1%'}}>
                3.  &emsp; To communicate with you, including responding to your inquiries and providing customer support.
                </Typography>
                <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'1%'}}>
                4.  &emsp; To send you important updates, promotions, and marketing communications.
                </Typography>
                <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'1%'}}>
                5.  &emsp; To detect, prevent, and address technical issues or fraudulent activities.
                </Typography>

                <Typography variant='h6' color='#19DBF6' style={{fontWeight:'bold',marginLeft:'5%',marginTop:'3%',fontSize:'28px'}}>
                Data Sharing
            </Typography>
            <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'1%'}}>
            We may share your personal information with third-party service providers who assist us in delivering our services or conducting business operations. These service providers are obligated to maintain the confidentiality and security of your information. We do not sell, trade, or rent your personal information to third parties for their marketing purposes.
           </Typography>
           <Typography variant='h6' color='#19DBF6' style={{fontWeight:'bold',marginLeft:'5%',marginTop:'3%',fontSize:'28px'}}>
           Data Security
            </Typography>
            <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'1%'}}>
            We implement industry-standard security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.  </Typography>
            <Typography variant='h6' color='#19DBF6' style={{fontWeight:'bold',marginLeft:'5%',marginTop:'3%',fontSize:'28px'}}>
            Data Retention
            </Typography>
            <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'1%'}}>
            We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.
             </Typography>
             <Typography variant='h6' color='#19DBF6' style={{fontWeight:'bold',marginLeft:'5%',marginTop:'3%',fontSize:'28px'}}>
             Your Rights
            </Typography>
            <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'1%'}}>
            You have the right to access, update, or delete your personal information. You can also opt-out of receiving marketing communications from us at any time by following the instructions provided in the communication or by contacting us directly.
              </Typography>
              <Typography variant='h6' color='#19DBF6' style={{fontWeight:'bold',marginLeft:'5%',marginTop:'3%',fontSize:'28px'}}>
              Changes to this Privacy Policy
            </Typography>
            <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'1%'}}>
            We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the updated Privacy Policy on our website. We encourage you to review this Privacy Policy periodically for any changes.
             </Typography>
             <Typography variant='h6' color='#19DBF6' style={{fontWeight:'bold',marginLeft:'5%',marginTop:'3%',fontSize:'28px'}}>
             Contact Us
            </Typography>
            <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'1%'}}>
            If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at
contact@rapifysms.com
 . </Typography>

 <Typography textAlign={'justify'}  color='gray' style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'2%',}}>
 By using our services, you consent to the collection and use of your information as described in this Privacy Policy.           
 </Typography>

<Typography textAlign={'justify'}  color='gray' style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'2%',}}>
Last Updated: [1st may 2024]</Typography>


<Typography textAlign={'justify'}  color='gray' style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'2%',}}>
Last Updated: [1st may 2024]</Typography>

<Typography textAlign={'justify'}  color='gray' style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'2%',}}>
This Privacy Policy applies solely to information collected by Rapify SMS. Please note that we are not responsible for the privacy practices of third-party websites or services linked to or from our website. We encourage you to review the privacy policies of those third parties before providing any personal information.</Typography>

            
        </Grid>
        </Layout>       
        
    );
}

export default Privacy_Policy;