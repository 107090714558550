import React from 'react';
import Layout from '../Components/Layout';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
const Terms_Services = () => {
    return (
        <Layout>
            <Grid  style={{marginTop:'5%'}}>
            
            <Typography variant='h3' color='black' textAlign={'center'} >
            Terms and Services
            </Typography>
            <Typography variant='body1' color='gray' textAlign={'center'} style={{marginTop:'3%',paddingLeft:'10%',paddingRight:'10%',fontSize:'20px',
            }} >Please carefully read the following terms and conditions and the FAQ before registering for our program. Participation in the program indicates your acceptance of these terms and conditions. If you do not agree to these terms and conditions, please refrain from registering for or participating in the program.</Typography>
 <Typography variant='h6' color='#19DBF6' style={{fontWeight:'bold',marginLeft:'5%',marginTop:'3%',fontSize:'28px'}}>
            
 Systematic Cheating Rackets Notice:
            </Typography>
            <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'1%'}}>
            We hereby notify our users that certain systematic cheating rackets operate on SMS platforms, disseminating lottery/prize/unclaimed property messages. Such activities are objectionable and against TRAI norms. We strictly prohibit the propagation of such messages and advise all users to adhere to this policy. Violation of this policy will result in legal action, and we will not be held responsible. We reserve the right to share client information with relevant authorities. </Typography>
            <Typography variant='h6' color='#19DBF6' style={{fontWeight:'bold',marginLeft:'5%',marginTop:'3%',fontSize:'28px'}}>
            Sender ID Approval:
                       </Typography>
                       <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'1%'}}>
                       Sender ID is subject to approval.
                       </Typography>
                       <Typography variant='h6' color='#19DBF6' style={{fontWeight:'bold',marginLeft:'5%',marginTop:'3%',fontSize:'28px'}}>
                       Liability for NDNC Claims:
                       </Typography>
                       <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'1%'}}>
                       Users who send SMS via normal gateways are 100% liable for any claims raised by NDNC (National Do Not Call) users. To mitigate this risk, users can utilize NDNC Gateway.
                       </Typography>
                       <Typography variant='h6' color='#19DBF6' style={{fontWeight:'bold',marginLeft:'5%',marginTop:'3%',fontSize:'28px'}}>
                       Account Suspension:
                       </Typography>
                       <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'1%'}}>
                       Your account may be suspended for violating any terms and conditions.
                       </Typography>
                       <Typography variant='h6' color='#19DBF6' style={{fontWeight:'bold',marginLeft:'5%',marginTop:'3%',fontSize:'28px'}}>
                       Promotional Communications:
                       </Typography>
                       <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'1%'}}>
                       By registering with our services, you grant us permission to send promotional offers, updates, or any contact materials to your registered mobile number or email address.
                       </Typography>
                       <Typography variant='h6' color='#19DBF6' style={{fontWeight:'bold',marginLeft:'5%',marginTop:'3%',fontSize:'28px'}}>
                       Force Majeure:
                       </Typography>
                       <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'1%'}}>
                       We shall not be liable for any interruption or delay in accessing the Site, regardless of the cause.
                        </Typography>
                        <Typography variant='h6' color='#19DBF6' style={{fontWeight:'bold',marginLeft:'5%',marginTop:'3%',fontSize:'28px'}}>
                        Termination/Suspension:
                       </Typography>
                       <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'1%'}}>
                       We reserve the right to refuse service, restrict, suspend, or terminate your account at any time, with or without cause, and with or without prior notice for any violation of the Terms of Use.
                       </Typography>
                       <Typography variant='h6' color='#19DBF6' style={{fontWeight:'bold',marginLeft:'5%',marginTop:'3%',fontSize:'28px'}}>
                       User Agreement:
                       </Typography>

                       <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'2%'}}>
                1.  &emsp; Users certify that they are at least 18 years of age or have the consent of a parent or legal guardian.
                </Typography>
                <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'1%'}}>
                2.  &emsp; All prices are in Indian Rupees unless indicated otherwise.
                </Typography>
                <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'1%'}}>
                3.  &emsp; Users are advised to verify the credibility of any particular user they deal with on the website.   </Typography>
                <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'1%'}}>
                4.  &emsp; Any order placed for a service listed at an incorrect price may be cancelled. </Typography>
                <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'1%'}}>
                5.  &emsp; Users must use their own credit cards for transactions.    </Typography>
                <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'1%'}}>
                6.  &emsp; We reserve the right to change fees for services. </Typography>
                <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'1%'}}>
                7.  &emsp;Users agree to use our services for lawful purposes and provide authentic information.  </Typography>

                <Typography variant='h6' color='#19DBF6' style={{fontWeight:'bold',marginLeft:'5%',marginTop:'3%',fontSize:'28px'}}>
                Data Accuracy:
                       </Typography>
                       <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'1%'}}>
                       We reserve the right to confirm and validate the information provided by users. If found untrue, we may reject registration without prior notice.  </Typography>
                       <Typography variant='h6' color='black' style={{fontWeight:'bold',marginLeft:'5%',marginTop:'3%',fontSize:'28px'}}>
                       Definitions:
                       </Typography>
                       <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'2%'}}>
                1.  &emsp; <span style={{ fontWeight: 'bold' }}>Agreement:</span> Refers to the Terms and Conditions contained herein along with the Privacy Policy and Terms of Use.
                </Typography>
                <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'2%'}}>
                2.  &emsp; <span style={{ fontWeight: 'bold' }}>Sites:</span> Refers to our portal and affiliated websites.
                </Typography>
                <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'2%'}}>
                3.  &emsp; <span style={{ fontWeight: 'bold' }}>Vendor/Seller/Affiliates:</span> Refers to entities offering services for sale on our website.
                </Typography>
                <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'2%'}}>
                4.  &emsp; <span style={{ fontWeight: 'bold' }}>User/You:</span> Refers to any customer or buyer who accepts the offer for sale of services on our site.
                </Typography>
                <Typography textAlign={'justify'} style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'2%'}}>
                5.  &emsp; <span style={{ fontWeight: 'bold' }}>service/s:</span> Refers to goods or services promoted or displayed on the site for sale or use.
</Typography>
<Typography textAlign={'justify'}  color='gray' style={{paddingLeft:'5%',paddingRight:'5%',fontSize:'20px',marginTop:'2%',}}>
By using our services, you agree to abide by these terms and conditions. If you have any questions or concerns, please contact us at
contact@rapifysms.com .        
 </Typography>
</Grid>
</Layout>
    );

}

export default Terms_Services;