import React from 'react'
import Layout from '../Components/Layout'

const Services = () => {
  return (
    <Layout>
      <h1>Service page</h1>
    </Layout>
  )
}

export default Services
