import higher_conversions from "../Assets/higher_conversion_logo.png";
import increase_response from "../Assets/increase_response_logo.png";
import higher_open_rate from "../Assets/higher_open_rate_logo.png";

export const percentageData = [
  {
    img:higher_conversions,
    number:'70%',
    header:'Higher conversions',
    subHeader:'than emails'
  },
  {
    img:increase_response,
    number:'60%',
    header:'Increase in response',
    subHeader:'to call to action'
  },
  {
    img:higher_open_rate,
    number:'35x',
    header:'Higher open rate',
    subHeader:'over emails'
  },
]