import React from 'react';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import logo_icon from "../Assets/ramptechno_logo.png";
import instagram_icon from "../Assets/instagram_icon.png";
import facebook_icon from "../Assets/facebook_icon.png";
import youtube_icon from "../Assets/youtube_icon.png";
import whatsapp_icon from "../Assets/whatsapp_icon.png";
import linkdin_icon from "../Assets/linkdin_icon.png";
import Mail from "../Assets/mail.png";

import footer_address_icon from "../Assets/footer_address_icon.png"
import footer_phone_icon from "../Assets/footer_phone_icon.png"
import { Link, NavLink } from 'react-router-dom';

const Footer = () => {
  const handleInstagramClick = () => {
    window.open('https://www.instagram.com/rapifysms/', '_blank');
  };

  const handlefacebookClick = () => {
    window.open('https://www.facebook.com/profile.php?id=61558666751179', '_blank');
  };
  const handlelinkdinClick = () => {
    window.open('https://www.linkedin.com/company/rapify-sms/', '_blank');
  };

  const handleWhatsAppClick = () => {
    const phoneNumber = '918249646500'; // WhatsApp phone number
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  };

  return (
    <>
      <Box>
        <Divider sx={{ border: "2px solid #0B0B0B4D" }} />
        <Grid container sx={{ padding: { lg: '30px 10px', md: '30px 6px', sm: '30px 6px', xs: '15px 0px 15px 0px' }, display: 'flex', justifyContent: { sm: 'center', xs: 'space-between' }, width: { sm: '100%' }, margin: "auto", p: "auto", flexDirection: { sm: 'row', xs: 'column' }, alignItems: { sm: 'flex-start', xs: 'flex-start' } }}>

          <Box width={"100%"}>
            <Typography sx={{
              textAlign: { sm: "left", xs: "center" },
              fontFamily: 'Osiris,Arial,sans-serif',
              "@media (max-width:346px)": { fontSize: "1.5em !important" },
              fontSize: '2em !important',
              fontWeight: "bolder !important",
              lineHeight: '51.43px',
              background: 'linear-gradient(90deg, #38B6FF 0%, #43C69B 10%) ',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent '
            }}><Link to={"/"}>RAPIFY SMS SOLUTIONS</Link></Typography>
          </Box>


          <Grid item sm={5} xs={12} sx={{
            '& > p': { fontFamily: 'poppins', fontWeight: '500', fontSize: { xl: '18px', lg: '16px', md: '14px', sm: '12px', xs: '12px' }, lineHeight: { xl: '27px', lg: '25px', md: '', sm: '15px', xs: '15px' } }
            , width: { xs: "80%" }, margin: { xs: "auto" }, '& > img': { Width: { xl: '230px', lg: '200px', md: '170px', sm: '300px', xs: "300px" }, height: { xl: '36px', lg: '30px', md: '25px', sm: '20px', xs: "19px" }, marginBottom: '15px' }
          }}>
            <Typography sx={{ display: 'flex', fontFamily: 'poppins', fontWeight: '500', fontSize: { xl: '22px', lg: '18px', md: '15px', sm: '15px', sx: '12px' }, lineHeight: { xl: '33px', lg: '28px', md: '21px', sm: '15px', sx: '15px' }, fontWeight: '500', fontFamily: 'poppins', margin: '10px 0px', '& > img': { width: { xl: '25px', lg: '22px', md: '20px', sm: '14px', xs: '14px' }, height: { xl: '25px', lg: '22px', md: '16px', sm: '14px', xs: '14px' }, marginRight: '10px' } }}>
              <img src={footer_phone_icon} alt="" />
              +91 8249646500
             
             
            </Typography>
            <Typography sx={{ display: 'flex', fontFamily: 'poppins', fontWeight: '500', fontSize: { xl: '22px', lg: '18px', md: '15px', sm: '15px', sx: '12px' }, lineHeight: { xl: '33px', lg: '28px', md: '21px', sm: '15px', sx: '15px' }, fontWeight: '500', fontFamily: 'poppins', margin: '10px 0px', '& > img': { width: { xl: '25px', lg: '22px', md: '20px', sm: '14px', xs: '14px' }, height: { xl: '25px', lg: '22px', md: '16px', sm: '14px', xs: '14px' }, marginRight: '10px' } }}>
              <img src={Mail} alt="" />
             
              info@rapifysms.com
             
             
            </Typography>
           
            


       

            <Typography sx={{ display: 'flex', '& > img': { width: { xl: '25px', lg: '22px', md: '20px', sm: '14px', xs: '14px' }, height: { xl: '25px', lg: '22px', md: '20px', sm: '14px', xs: '14px' }, marginRight: '10px' }, margin: { lg: '30px 0px', sm: '12px 0px', xs: 'auto' } }}>
              <img src={footer_address_icon} alt="" />

              <Box sx={{ display: 'flex', flexDirection: 'column', '& > p': { fontSize: { xl: '19px', lg: '16px', md: '12px', sm: '9px', xs: '12px' }, fontWeight: '500', fontFamily: 'poppins', lineHeight: { xl: '28.5px', lg: '25px', md: '20px', sm: '14px', xs: '15px' } } }}>
                <Typography><Typography sx={{ fontSize: { xl: '19px', lg: '16px', md: '12px', sm: '9px', xs: '12px' }, fontWeight: '700', fontFamily: 'poppins', lineHeight: { xl: '28.5px', lg: '25px', md: '20px', sm: '14px', xs: '15px' } }} component={'span'}>Headquarter</Typography>-1532, Aerodrome Area, Bhimpur,</Typography>
                <Typography> New Forest park, Bapuji nagar,</Typography>
                <Typography> Bhubaneswar, Odisha, 751020</Typography>

                <Typography><Typography sx={{ fontSize: { xl: '19px', lg: '16px', md: '12px', sm: '9px', xs: '12px' }, fontWeight: '700', fontFamily: 'poppins', lineHeight: { xl: '28.5px', lg: '25px', md: '20px', sm: '14px', xs: '15px' } }} component={'span'}>General office</Typography>-Mysore Rd, RV Vidyaniketan, </Typography>
                <Typography>Rajarajeshwari Nagar, Bengaluru,</Typography>
                <Typography>Karnataka - 560059</Typography>
              </Box>

            </Typography>

            <Box sx={{ paddingTop: '12px', display: 'flex', margin: "auto", justifyContent: { sm: "start", xs: 'space-between' }, '& > .MuiIconButton-root': { border: '1px solid grey', padding: { xl: '8px', lg: '6px', md: '5px', sm: '4px', xs: '4px' }, margin: '0px 5px' }, '& > .MuiIconButton-root > img': { width: { xl: '28px', lg: '24px', md: '20px', sm: '20px', xs: '20px' }, height: { xl: '28px', lg: '24px', md: '20px', sm: '20px', xs: '20px' } } }}>
              <IconButton onClick={handleInstagramClick}>
                <img src={instagram_icon} alt="instagram_icon" />
              </IconButton>

              <IconButton onClick={handlefacebookClick}>
                <img src={facebook_icon} alt="facebook_icon" />
              </IconButton>


              <IconButton onClick={handlelinkdinClick}>
                <img src={linkdin_icon} alt="linkdin_icon" />
              </IconButton>



              <IconButton onClick={handleWhatsAppClick}>
                <img src={whatsapp_icon} alt="whatsapp_icon" />
              </IconButton>
            </Box>

          </Grid>

          <Grid item sm={3}
            sx={{ margin: { md: '0px 0px', xs: '30px auto' }, display: "flex", justifyContent: "space-between", flexDirection: { sm: "column", xs: "row" }, width: { xs: "80%" } }}
          >
            <Box>
              <Typography component={'span'} sx={{ fontWeight: '700', fontFamily: 'poppins', fontSize: { xl: '30px', lg: '25px', md: '22px', sm: '14px', sx: '12px' }, borderBottom: '2px solid black' }} >QuickLinks</Typography>
              <Box sx={{ '& > p': { fontFamily: 'poppins', fontWeight: '400', fontSize: { xl: '25px', lg: '20px', md: '17px', sm: '11px', sx: '10px' }, lineHeight: { xl: '50px', lg: '40px', md: '30px', sm: '22px', sx: '' }, fontWeight: '400' }, '& > p >a': { textDecoration: 'none', color: '#0B0B0B' } }}>
                <Typography><Link to={'/about'}>About Us</Link></Typography>
                <Typography><Link to={'/contact'}>Contact Us</Link></Typography>
              </Box>
            </Box>

            <Box>
              <Typography component={'span'} sx={{ fontWeight: '700', fontFamily: 'poppins', fontSize: { xl: '30px', lg: '25px', md: '22px', sm: '14px', sx: '12px' }, borderBottom: '2px solid black' }} >Pricing</Typography>
              <Box sx={{ '& > p': { fontFamily: 'poppins', fontWeight: '400', fontSize: { xl: '25px', lg: '20px', md: '17px', sm: '11px', sx: '10px' }, lineHeight: { xl: '50px', lg: '40px', md: '30px', sm: '22px', sx: '' }, fontWeight: '400' }, '& > p >a': { textDecoration: 'none', color: '#0B0B0B' } }}>
                <Typography><Link to={'/transactional_sms'}>Bulk SMS</Link></Typography>
                <Typography><Link to={'/virtual_whatsapp'}>Bulk WhatsApp</Link></Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item sm={3} xs={12} sx={{ paddingTop: { sm: '0px', xs: '12px' }, width: { xs: "80%" }, margin: { xs: "auto" } }}>
            <Typography component={'span'} sx={{ fontWeight: '700', fontFamily: 'poppins', fontSize: { xl: '30px', lg: '25px', md: '22px', sm: '14px', sx: '12px' }, borderBottom: '2px solid black' }}>Services</Typography>

            <Box sx={{ '& > p': { fontFamily: 'poppins', fontWeight: '400', fontSize: { xl: '25px', lg: '20px', md: '17px', sm: '11px', sx: '10px' }, lineHeight: { xl: '50px', lg: '40px', md: '30px', sm: '22px', sx: '' }, fontWeight: '400' }, '&> p >a': { textDecoration: 'none', color: '#0B0B0B' } }}>
              <Typography><Link to={'/transactional_sms'}>Transactional SMS</Link></Typography>
              <Typography><Link to={'/promotional_sms'}>Promotional SMS</Link></Typography>
              <Typography><Link to={'/high_priority_otp_based'}>High Priority OTP Based (API)</Link></Typography>
              <Typography><Link to={'/own_number'}>Own Number WhatsApp</Link></Typography>
              <Typography><Link to={'/virtual_whatsapp'}>Virtual WhatsApp</Link></Typography>
              <Typography><Link to={'/green_tick_verified'}>Green Tick Verified</Link></Typography>
            </Box>
          </Grid>

        </Grid>

        {/*------------lower footer------------  */}

        <Box component="section" sx={{ width: '100%', height: '100px', textAlign: 'center', bgcolor: '#F1F1F1', color: '#2c3e50', display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderTop: '1px solid #0B0B0B4D', padding: '10px 40px' }}>

          <Grid container sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
            <Grid item sm={6} xs={12} sx={{ display: 'flex', justifyContent: { sm: 'flex-start', xs: 'center' } }}>
              <Typography component={'div'} sx={{ fontWeight: '400', fontFamily: 'poppins', fontSize: { md: '1rem', sm: '1rem', xs: '0.7rem' } }}>
                Rapify &copy; 2024. All rights reserved.
              </Typography>
            </Grid>

            <Grid item sm={6} xs={12} sx={{ display: 'flex', justifyContent: { sm: 'flex-end', xs: 'center' }, alignItems: 'center', '& > .MuiBox-root > .MuiTypography-root': { md: '1rem', sm: '.8rem' } }}>
              <Box sx={{ display: 'flex', '& > p > a': { textDecoration: 'none', color: '#0B0B0B' } }}>
                <Typography sx={{ fontWeight: '400', fontFamily: 'poppins', margin: '0px 10px', fontSize: { md: '1rem', sm: '.8rem', xs: '0.7rem' } }}>
                  <Link to={'/terms_services'}>Terms Of Services</Link>
                </Typography>
                <Divider orientation="vertical" sx={{ borderRightWidth: '3px' }} flexItem />
                <Typography sx={{ fontWeight: '400', fontFamily: 'poppins', margin: '0px 10px', fontSize: { md: '1rem', sm: '.8rem', xs: '0.7rem' } }}>
                  <Link to={'/privacy_policy'}>Privacy Policy</Link>
                </Typography>
                <Divider orientation="vertical" sx={{ borderRightWidth: '3px' }} flexItem />
                <Typography sx={{ fontWeight: '400', fontFamily: 'poppins', margin: '0px 10px', fontSize: { md: '1rem', sm: '.8rem', xs: '0.7rem' } }}>
                  <Link to={'/refund_policy'}>Refund Policy</Link>
                </Typography>
              </Box>
            </Grid>
          </Grid>

        </Box>
      </Box>
    </>
  )
}

export default Footer
